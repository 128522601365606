import React from 'react';
import { Container } from 'react-bootstrap';
import { FaCalendar, FaTrophy, FaHome, FaMoneyCheck, FaLongArrowAltRight, FaClipboardCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';

const content = [
  {
    Icon: FaClipboardCheck,
    title: 'Free to try',
    description: 'You don\'t need a membership to attempt the skills and climb the leaderboard!  Just sign-in, create your profile and start uploading your attempts.  If you want to be eligible for medals and monthly/weekly prizes, you\'ll need to start your membership.'
  },
  {
    Icon: FaCalendar,
    title: 'Monthly Seasons',
    description: 'Ninja Skills League is split into monthly "seasons".  Starting on the 1st of the month, a new season will start. During the season, you can attempt as many skills as you want.  You earn points for every skill you attempt.'
  },
  {
    Icon: FaTrophy,
    title: 'League Leaderboard',
    description: 'You can check our your standings at any time. The leaderboard is updated in realtime and shows your ranking on both the individual skill and the monthly leaderboard.'
  },
  {
    Icon: FaMoneyCheck,
    title: 'Prizes',
    description: 'Prizes (holds & gift cards) will be given out based on the rank and the number of skills in each division. The more ninjas sign up and compete the bigger the prize pool!'
  }
]
function HowItWorks() {
  return (
    <Container>
      <h2>How It Works</h2>
      <div className="embed-responsive embed-responsive-4by3">
        <div className="embed-responsive-item shadow p-3 mb-5 bg-white rounded">
          <ReactPlayer url="https://youtu.be/HRuf4oseYVY" controls />
        </div>
      </div>
      <br/>
      <ul className="list-unstyled text-left">
        {
          content.map((item, i) => {
            return (
              <div key={i} className="shadow p-3 mb-5 bg-white rounded">
                <h5 className="mt-0 mb-1">
                  <item.Icon size="20px" className="mr-1" /> 
                  {item.title}
                </h5>
                <p>
                  {item.description}
                </p>
              </div>
            )
          })
        }
      </ul>
      <div className="text-right">
        <Link as="button" className="btn btn-link" to="/GettingStarted">Getting Started <FaLongArrowAltRight /></Link>
      </div>
    </Container>
  )
}

export default HowItWorks;