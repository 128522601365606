import React from 'react';
import { getAge } from 'services/Utilities';
import Avatar from 'components/users/Avatar';
import Flair from 'components/users/Flair';

function UserInfo({profile}) {
  if (!profile) { return null; }
  
  return (
    <>
      <Avatar profile={profile} />
      <h2 style={{fontSize: '4vmin'}}>
        {profile.displayName}
        <div className="small text-muted">
          Age {getAge(profile.birthday)}
        </div>
      </h2>
      <Flair flair={profile.flair} />
    </>
  )
}

export default UserInfo;