import React, { useState, useCallback } from 'react';
import PeriodDropdown from 'components/PeriodDropdown';
import AgeGroupToggle from 'components/leaderboard/AgeGroupToggle';
import LeaderboardToggle from 'components/leaderboard/LeaderboardToggle';
import Leaderboards from 'components/leaderboard/Leaderboards';


function Leaderboard() {
  
  const [period, setPeriod] = useState();
  const [visibleLocations, setVisibleLocations] = useState();
  const [locations, setLocations] = useState();
  const [resultsFilters, setResultsFilters] = useState();
  
  const handleAgeGroup = (isInAgeGroup) => {
    setResultsFilters([isInAgeGroup]);
  }

  const handleLocationChange = val => {
    setLocations(val);
    setVisibleLocations(val);
  }

  return (
    <>
      <h2 className="mb-1">Leaderboards</h2>
      <h5 className="text-secondary">
        <PeriodDropdown includeCurrent={true} period={period} onPeriodChange={setPeriod} />
      </h5>
      <LeaderboardToggle visibleLocations={visibleLocations} locations={locations} onChange={setVisibleLocations} />
      <br/>
      <AgeGroupToggle onChange={handleAgeGroup} />
      <div className='mb-2'>&nbsp;</div>
      <Leaderboards period={period} visibleLocations={visibleLocations} resultsFilters={resultsFilters} onLocationChange={handleLocationChange} />
    </>
  )
}

export default Leaderboard;