import React from 'react';
import { FaCogs, FaQuestionCircle, FaEnvelope, FaInfoCircle, FaInstagram, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Help() {
  return (
    <div className="container">
      <div className="row row-cols-1">
        <div className="col mb-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title"><FaEnvelope/> Contact Us</h5>
                <div className="text-left">
                  <FaEnvelope/> <a href="mailto:support@skillsleague.ninja" target="_blank" rel="noopener noreferrer" className="card-link">support@skillsleague.ninja</a><br/>
                  <FaTwitter /> <a href="https://twitter.com/ninjaskillsleag" target="_blank" rel="noopener noreferrer" className="card-link">@ninjaskillsleag</a><br/>
                  <FaInstagram /> <a href="https://instagram.com/skillsleague.ninja" target="_blank" rel="noopener noreferrer" className="card-link">@skillsleague.ninja</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="row row-cols-1 row-cols-md-2">
        <div className="col mb-4">
          <div className="card h-100 bg-primary text-white">
            <div className="card-body">
              <h5 className="card-title"><FaCogs/> How It Works?</h5>
              <p className="card-text">The newest virtual ninja league!</p>
            </div>
            <div className="card-footer">
              <Link to="/HowItWorks" className="text-white stretched-link">Click to find out more!</Link>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card h-100 bg-primary text-white">
            <div className="card-body">
              <h5 className="card-title"><FaCogs/> Getting Started</h5>
              <p className="card-text">Let us walk you through a tutorial on the league!</p>
            </div>
            <div className="card-footer">
              <Link to="/GettingStarted" className="text-white stretched-link">Click to get started.</Link>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card h-100 bg-secondary text-white">
            <div className="card-body">
              <h5 className="card-title"><FaQuestionCircle/> FAQs</h5>
              <p className="card-text">You have questions, we have answers!</p>
            </div>
            <div className="card-footer">
              <Link to="/FAQ" className="text-white stretched-link">Click to view FAQs</Link>
            </div>
          </div>
        </div>
        
        <div className="col mb-4">
          <div className="card">
            <div className="card-body bg-secondary text-white">
              <h5 className="card-title"><FaInfoCircle/> About Us</h5>
              <p className="card-text">
                The newest virtual ninja league!
              </p>
              <p className="card-text">
                Brought to you by the awesome folks from Gripz Gym in Detroit, Michigan, Ninja Skills League was born of the need to compete during a time of social distancing.
                We wanted to develop a league that anyone could join, would be challenging, and of course, FUN!!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help;