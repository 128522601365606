import React, { useEffect, useState } from 'react';
import SortableTable from 'components/SortableTable';
import { useFirebase } from 'services/Firebase';
import { useUser } from 'services/User';
import { trackPromise } from 'react-promise-tracker';
import { Season } from 'services/Utilities';
import { Badge, Modal, Button } from 'react-bootstrap';
import Tooltip from 'components/Tooltip';
import WinningsRedemption from './WinningsRedemption';


function WinningsTable () {
  const {user} = useUser();
  const authId = user.authId;
  const uid = user.uid;

  const db = useFirebase().db;
  const [total, setTotal] = useState(0);
  const [redeemedTotal, setRedeemedTotal] = useState(0);
  const [pendingTotal, setPendingTotal] = useState(0);
  const [unusedTotal, setUnusedTotal] = useState(0);
  const [winnings, setWinnings] = useState([]);
  const [show, setShow] = useState(false);
  const [redeemed, setRedeemded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      if (!uid) return;
      
      const winList = [];
      const redeemList = [];
      const winningsDoc = await db.collection('users').doc(authId).collection('athletes').doc(uid).collection('private').doc('winnings').get();
      if (winningsDoc.exists) {
        const win = winningsDoc.data();
        if (!(Array.isArray(win.prizes) && win.prizes.length)) return;

        winList.push(...win.prizes);

        if (Array.isArray(win.redemptions) && win.redemptions.length) {
          redeemList.push(...win.redemptions);
        }
      }
      const total = winList.reduce((a, b) => {return {amount: a.amount + b.amount};}, {amount: 0}).amount;
      let pendingTotal = 0;
      let redeemedTotal = 0;
      redeemList.forEach((redemption) => {

        if (redemption.redeemed) {
          redeemedTotal += redemption.amount || 0;
        } else {
          pendingTotal += redemption.amount || 0;
        }
      })
      setTotal(total);
      setRedeemedTotal(redeemedTotal);
      setPendingTotal(pendingTotal);
      const unused = total - redeemedTotal - pendingTotal;
      setUnusedTotal(unused);
      setWinnings(winList);
    }

    trackPromise(getData());
  }, [db, uid, redeemed])
  

  const formatPrize = (amount) => {
    if (typeof amount === 'undefined') return null;
    return '$'+amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  const data = React.useMemo(() => {
    return winnings;
  }, [winnings]);
  const columns = React.useMemo(() => [
    {
      Header: <div>
          <h4>Winnings <Tooltip>Minimum $10 Winnings required for prizes</Tooltip></h4>
          <div className="small">{user.email}</div>
          <br/>
          <span className="float-left"><Badge variant="light">Lifetime: {formatPrize(total)}</Badge></span><br/>
          <span className="float-left"><Badge variant="secondary">Redeemed: {formatPrize(redeemedTotal)}</Badge></span><br/>
          <span className="float-left"><Badge variant="warning">Pending: {formatPrize(pendingTotal)}</Badge></span>
          <span className="float-right">
            {unusedTotal < 10 ? 
            <Badge variant="light">Available: {formatPrize(unusedTotal)}</Badge>
            :
            <Button variant="info" size="sm" onClick={() => setShow(true)}>Redeem: {formatPrize(unusedTotal)}</Button>
            }
          </span>
        </div>,
      id: 'winnings',
      columns: [
        {
          Header: 'Season',
          accessor: 'season',
          Cell: ({value}) => Season({seasonId: value})
        },
        {
          Header: 'Period',
          accessor: 'period'
        },
        {
          Header: 'Division',
          accessor: 'location'
        },
        {
          Header: 'Amount',
          accessor: 'amount',
          id: 'amount',
          Cell: ({value}) => formatPrize(value) 
        }
      ]
    }
  ], [total, pendingTotal, redeemedTotal, unusedTotal])

  const handleRedeemed = () => {
    setRedeemded(true);
    setShow(false);
  }

  return (
    <>
      <SortableTable columns={columns} data={data} /> 
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Body><WinningsRedemption unused={unusedTotal} onRedeemed={handleRedeemed} /></Modal.Body>
        <Modal.Footer><Button size="lg" block variant="secondary" onClick={() => setShow(false)}>Close</Button></Modal.Footer>
      </Modal>
    </>
  )
}

export default WinningsTable;