import React, {useState} from 'react';
import { FaCheckSquare, FaVideo } from 'react-icons/fa';
import Video from 'components/video/Video';
import { Button, Modal, Card } from 'react-bootstrap';


function SkillDetails({skill}) {
  const [show, setShow] = useState(false);

  skill = skill || {};
  const equipment = skill.equipment && skill.equipment.toString();
  const rules = skill.rules && skill.rules.split(/\r?\n/g).map((rule, i) => {
    return (
      <div key={i}><FaCheckSquare /> {rule}</div>
    )
  })
  const tips = skill.videoTips && skill.videoTips.split(/\r?\n/g).map((tip, i) => {
    return (
      <div key={i}><FaVideo /> {tip}</div>
    )
  })
  const handleClose = () => setShow(false);

  return (
    <>
      <h4>{skill.name}</h4>
      <p>{skill.description}</p>
      <p>{skill.weeks && <>Active Weeks: {skill.weeks.toString()}</>}</p>
      <div>
        <Button variant="outline-secondary" onClick={() => setShow(true)} className="mb-2"><FaVideo /> Watch Walkthrough Video</Button>
        <Modal show={show} onHide={handleClose} size="lg" centered>
          <Video src={skill.videoURL} />
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal>
      </div>
      <Card bg="secondary" text="light">
        <Card.Body>
          <p className="text-left"><strong>Equipment: </strong> {equipment || 'None'}</p>
          <div className="text-left">
            {rules}
            <br />
            {tips}
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default SkillDetails;