import React from 'react';


function TOS() {

  return (
    <div className="container">
      <h3>Terms &amp; Conditions</h3>
      <div className="text-left">
        <p>1. Terms
            <br />
            By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trade mark law.
        </p>
        <p>2. Video Content
            <br/>
            We will not review the content of each video submitted through our website. If you find content that promotes hate, we will remove it after a thorough review. If you upload content that promotes hate, you will be banned without refund. Be nice and have fun.
            </p>
        <p>3. Video Use
            <br/>
            You agree to allow Ninja Skills League to use any submitted videos and pictures in promotional materials for the league.</p>
        <p>4. Taxes
            <br/>
            You are responsible for determining any tax liability regarding winnings.</p>
        <p>5. Governing Law
            Any claim relating to Ninja Skills League's web site shall be governed by the laws of the State of Michigan without regard to its conflict of law provisions.</p>
      </div>
    </div>
  )
}

export default TOS;