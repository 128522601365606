import React, { useState, useCallback, useEffect } from 'react';
import InfiniteVideoPlayer from 'components/video/InfiniteVideoPlayer';
import PeriodDropdown from 'components/PeriodDropdown';
import { Container } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import { useFirebase } from 'services/Firebase';
import SkillSort from 'components/skills/SkillSort';
import { getLocalDT } from 'services/Utilities';

let sortStatus = {
  currentSort: 'Date',
  sortDirection: -1, //1: ascending, -1: descending
  sortKeys: {
    Date:  (a, b) => (a.modifiedDate - b.modifiedDate)*sortStatus.sortDirection,
    Skill:  (a, b) => {
      if (a.skillName < b.skillName) {
        return (-1*sortStatus.sortDirection);
      } 
      if (a.skillName > b.skillName) {
        return 1*sortStatus.sortDirection;
      }
      return b.reps - a.reps;
    }
  }
};

function TV() {
  const [attempts, setAttempts] = useState();
  const [period, setPeriod] = useState();

  const db = useFirebase().db; 

  const handleChange = useCallback((period) => {
    setPeriod(period);
    if (!(period && period.seasonId && period.period)) return;
    
    const getData = async () => {
      const attemptSnap = await db.collection('seasons').doc(period.seasonId)
        .collection('periods').doc(period.period)
        .collection('attempts')
        .get()
        .catch(err => {throw new Error(err)});

      const attempts = [];
      attemptSnap.forEach((doc) => {
        const attempt = doc.data();
        attempts.push(attempt);
      });

      setAttempts(sortList(attempts, 'Date'));
    }

    trackPromise(getData());
  }, []);

  function handleSort(key) {
    if (key === sortStatus.currentSort) {
      sortStatus.sortDirection *= -1;
    } else {
      sortStatus.sortDirection = 1;
    }
    sortStatus.currentSort = key;
    setAttempts(sortList(attempts, key));
  };
  function sortList(list, key) {
    if (!key) {
      key = sortStatus.currentSort;
    }
    let sortFn = sortStatus.sortKeys[key];
    const sorted = list.sort(sortFn);
    return sorted.slice();
  }; 

  return (
    <Container>
      <h2 className="mb-1">Skill TV</h2>
      <PeriodDropdown includeCurrent={false} onPeriodChange={handleChange} period={period} />
      <br />
      <div className="text-right">
        <SkillSort status={sortStatus} onSort={handleSort} />
      </div>
      {Array.isArray(attempts) && attempts.length?
        <InfiniteVideoPlayer attempts={attempts} key={JSON.stringify(period) + JSON.stringify(sortStatus)}/>
        :
        <h4>No available videos</h4>
      }
    </Container>
  )
}

export default TV;