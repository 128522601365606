import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FaTwitter, FaInstagram, FaEnvelope } from 'react-icons/fa';

function Footer () {
  const year = DateTime.local().toFormat('yyyy');

  return (
    <div className="pl-2 small text-muted">
      <Link to="Privacy">Privacy Policy</Link> 
      &nbsp;|&nbsp;
      <Link to="TOS">Terms &amp; Conditions</Link>
      &nbsp;|&nbsp;
      <a href="mailto:support@skillsleague.ninja" target="_blank">
        <FaEnvelope/> 
        <span className="d-none d-md-inline">support@skillsleague.ninja</span>
      </a>
      &nbsp;|&nbsp;
      <a href="https://twitter.com/ninjaskillsleag" target="_blank">
        <FaTwitter /> 
        <span className="d-none d-md-inline">ninjaskillsleag</span>
      </a>
      &nbsp;|&nbsp;
      <a href="https://instagram.com/skillsleague.ninja" target="_blank">
        <FaInstagram /> 
        <span className="d-none d-md-inline">skillsleague.ninja</span>
      </a>
    </div>
  )
}

export default Footer;