import React from 'react';
import { firebase } from 'services/Firebase';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error: error.message });
    // You can also log the error to an error reporting service
    console.error(error);
    firebase.logError({error: error, info: info});
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>Something went wrong!</h1>
          <p>{this.state.error}</p>
        </>
      )
    }
    return this.props.children;
  }
}