import React from 'react';
import { Carousel } from 'react-bootstrap';
import './GettingStarted.css';


function GettingStarted () {
  
  return (
    <div className="container-fluid bg-dark p-0">
      <Carousel>
        <Carousel.Item>
          <img alt="Tutorial 1" src="/tutorial1.svg" />
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Tutorial 2" src="/tutorial2.svg"   />
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Tutorial 3" src="/tutorial3.svg"   />
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Tutorial 4" src="/tutorial4.svg"   />
        </Carousel.Item>
        <Carousel.Item>
          <img alt="Tutorial 5" src="/tutorial5.svg"   />
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default GettingStarted;