import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { usePromiseTracker } from 'react-promise-tracker'; 

function Loader(props) {
  const { promiseInProgress } = usePromiseTracker({area: props.area});
  const message = props.message;
  const spin = (
    <>
      <Spinner className="mr-2" animation="border" role="status" size={props.size}>
      </Spinner>
      {message && <><br/>{message}</>}
    </>
  );
  let loading = spin;
  if (!props.area || props.fullScreen) {
    loading = (
      <Modal size="sm" show={promiseInProgress || props.show} aria-label="Loading" animation="false" centered>
        <Modal.Body className="text-center">
          {spin}
        </Modal.Body>
      </Modal>
    );
  }
  
  return (
    <>
      {promiseInProgress && loading}
    </>
  );
}

export default Loader;