import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const faqs = [
  {
    title: 'General',
    items: [
      {
        q: 'How does it work?',
        a: <p>Check out <Link to='/HowItWorks'>How It Works</Link> for details.</p>
      },
      {
        q: 'Are there age groups?',
        a: 
          <>
            <p>Yes and no. The prize pool will be divided up based on overall performance.</p>
            <p>But, we will also have medals for the following groups <strong>as long as there are at least 5 members in that group</strong>. <em>Limit 1 medal/person per division (Home/Gym)</em></p>
            <ul>
              <li>Overall</li>
              <li>Overall by Gender</li>
              <li>Age Group Overall</li>
              <li>Age Group by Gender</li>
            </ul>
          </>
      },
      {
        q: 'What are the age groups?',
        a: '1-10 Years Old, 11-14 Years Old, 15-39 Years Old and 40+ Years Old. Your age will be based on the last day of the current season (month).'
      }
    ]
  },
  {
    title: 'Membership',
    items: [
      {
        q: 'How do I become a member?',
        a: <Link to='/Join'>Sign up here</Link>
      },
      {
        q: 'What is the cost?',
        a: <p>Membership dues are $9.95 per month. Or $8.45 for <a href="https://theninjapassport.com/" target="_blank">Ninja Passport</a> members.</p>
      },
      {
        q: 'When can I join?',
        a: 'You can join at any time. Once you purchase a membership, you can start attempting the skills for the current season.'
      },
      {
        q: 'When can I cancel?',
        a: 'You can cancel at any time.  However, if you cancel before the end of a season or week, your results for that season/week will not count.'
      },
      {
        q: 'How do I cancel?',
        a: <p>Go to your <Link to='/User'>Profile Page</Link> to cancel.</p>
      },
      {
        q: 'Can I have a refund?',
        a: <p>Unfortunately, the prize pool is based on the number of members, so we are not able to refund any memberships. If you have an issue, <Link to="/Help">please contact support.</Link></p>
      },
      {
        q: 'What happens to my account when I cancel?',
        a: 'All data will be retained for 12 months after your membership is cancelled.'
      }
    ]
  },
  {
    title: 'Skills',
    items: [
      {
        q: 'What\s considered a "Ninja Skill"?',
        a: 'An activity that can be completed multiple times in a minute and incorporates one or more of the following: Agility, Strength, Balance, Power, Fun.'
      },
      {
        q: 'How many times can I attempt a skill?',
        a: 'You can attempt and upload a skill as many times as you want before the season ends.  Only your last attempt will be saved.'
      },
      {
        q: 'What if I can\'t complete a skill.',
        a: 'Many of the skills will be challenging, so do your best.  If you are unable to complete a skill, try a different one.  Remember, you have a whole season to attempt each skill.'
      },
      {
        q: 'Should I submit if I can\'t beat the leaderboard?',
        a: 'YES. You never know if a video review can change the rankings. Also, it\'s fun to compete and share your journey with fellow ninjas!!'
      },
      {
        q: 'When is the submission deadline for the season?',
        a: 'Before midnight Eastern Time (US) on the last day of the month.'
      },
      {
        q: 'When is the submission deadline for the skills?',
        a: 'Before midnight Eastern Time (US) on the skill end date.'
      },
      {
        q: 'What if I miss the submission deadline?',
        a: 'Late submissions will not be accepted. Try submitting early so you don\'t face this issue.'
      }
    ]
  },
  {
    title: 'Scoring',
    items: [
      {
        q: 'How are skills scored?',
        a: 'Each individual skill is scored based on the number of successful completions (or reps) of the skill within the time limit.'
      },
      {
        q: 'How is the season scored?',
        a: <p>You receive 1 point for each skill you attempt and 1 point for each competitor that you beat in that skill. Your total for each skill is added together to give you your season total.<br/>
        For example, if you attempted Skill 1 and beat 25 others, you would get 26 points. If you also attempted Skill 2 but did not beat anyone, you would get 1 point and your season total would be 27 points.</p> 
      },
      {
        q: 'What if someone tries to cheat?',
        a: 'First, they would not be a good ninja! Second, all of the members up for prizes will have their attempts manually reviewed. Anyone found cheating will be banned from participating in the league.'
      },
      {
        q: 'What keeps someone from waiting until the last minute to submit?',
        a: 'If you submit before Thursday, you will get you video reviewed (paid accounts only), so you will have a change to submit again if there are any issues with your attempt. If you wait until last minute, you won\'t have a change to fix any issues with your attempt before the deadline.'
      }
    ]
  },
  {
    title: 'Prizes',
    items: [
      {
        q: 'What are the prizes?',
        a: 'The prizes could change every month, from gift cards to holds to other surpises. The number and size of the prize pool will change bases on the number of members in the league.  Roughly $5 of each membership goes toward prizes. Current prizes include gift cards from Monstro (min. $10), Atomik (min. $25), Stronghold (min. $25)'
      },
      {
        q: 'How is prize pool divided?',
        a: <>
          <p>The monthly prize pool is divided between monthly prizes (25%) and skill prizes (75%).<br/>
          For Example: <br/> </p>
          <dl className="row">
            <dt className="col-3 text-right">$4,000</dt>
            <dd className="col-9">Monthly Prize Pool</dd>
            <dt className="col-3 text-right">10</dt>
            <dd className="col-9">Skills</dd>
            <dt className="col-3 text-right">$1,000</dt>
            <dd className="col-9">Monthly Prizes</dd>
            <dt className="col-3 text-right">$300</dt>
            <dd className="col-9">Skill Prizes</dd>
          </dl>
          </>
      },
      {
        q: 'How are the prizes divided?',
        a: <p>The prizes will divided based on the size of the prize pool.<br/>
              <dl className="row">
                <dt className="col-3 text-right">$0-$10</dt>
                <dd className="col-9">
                  1st Place - 100%
                </dd>
                <dt className="col-3 text-right">$11-$20</dt>
                <dd className="col-9">
                  1st Place - 75%<br/>
                  2nd Place - 25%
                </dd>
                <dt className="col-3 text-right">$21-$50</dt>
                <dd className="col-9">
                  1st Place - 50%<br/>
                  2nd Place - 30%<br/>
                  3rd Place - 20%
                </dd>
                <dt className="col-3 text-right">$51-$100</dt>
                <dd className="col-9">
                  1st Place - 50%<br/>
                  2nd Place - 25%<br/>
                  3rd Place - 15%<br/>
                  4th Place -  5%<br/>
                  5th Place -  5%
                </dd>
                <dt className="col-3 text-right">$101-$200</dt>
                <dd className="col-9">
                  1st Place - 40%<br/>
                  2nd Place - 25%<br/>
                  3rd Place - 15%<br/>
                  4th-7th Place -  5%<br/>
                </dd>
                <dt className="col-3 text-right">$201-$500</dt>
                <dd className="col-9">
                  1st Place - 25%<br/>
                  2nd Place - 20%<br/>
                  3rd Place - 15%<br/>
                  4th Place - 10%<br/>
                  5th-10th Place - 5%
                </dd>
                <dt className="col-3 text-right">$501-$1,000</dt>
                <dd className="col-9">
                  1st Place - 20%<br/>
                  2nd Place - 10%<br/>
                  3rd Place -  7%<br/>
                  4th Place -  5%<br/>
                  5th Place -  3%<br/>
                  6th-20th Place -  2%<br/>
                  21st-45th Place - 1%
                </dd>
                <dt className="col-3 text-right">$1,001-$2,000</dt>
                <dd className="col-9">
                  1st Place - 10%<br/>
                  2nd Place -  6%<br/>
                  3rd Place -  5%<br/>
                  4th Place -  4%<br/>
                  5th Place -  3%<br/>
                  6th-20th Place -  2%<br/>
                  21st-45th Place - 1%<br/>
                  46th-79th Place - 0.5%

                </dd>
                <dt className="col-3 text-right">$2,001+</dt>
                <dd className="col-9">
                  1st Place - 7%<br/>
                  2nd Place -  5%<br/>
                  3rd Place -  4%<br/>
                  4th Place -  3.5%<br/>
                  5th Place -  3%<br/>
                  6th-20th Place -  2%<br/>
                  21st-45th Place - 1%<br/>
                  46th-79th Place - 0.5%<br/>
                  80th-101th Place - 0.25%
                </dd>
              </dl>
          </p>
      },
      {
        q: 'When do I get paid??',
        a: 'Prizes are sent out 2 weeks after the end of the month.'
      },
      {
        q: 'Is there a minimum amount of winnings to redeem for prizes?',
        a: 'Yes. Based on the companies we are using for prizes, the current minimum redeption is $10'
      }
    ]
  }
];
function FAQ() {
  return (
    <>
      <h2>Frequently Asked Questions</h2>
      <br></br>
      <ListGroup variant="flush" className="text-left">
      {
        faqs.map((faq) => {
          return (
            <div key={faq.title}>
              <h4>{faq.title}</h4>
              {
                faq.items.map((item) => {
                  return (
                    <ListGroup.Item key={item}>
                    <strong>{item.q}</strong>
                    <br></br>
                    {item.a}
                  </ListGroup.Item>
                  )
                })
              }
            </div>
          )
        })
      }
      </ListGroup>
    </>
  )
}

export default FAQ;