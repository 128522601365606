import React, { useState, use, useEffect } from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import { ProgressBar, Spinner, Button, Modal } from 'react-bootstrap';
import { useFirebase } from 'services/Firebase';
import { FaVideo } from 'react-icons/fa';
import Video from 'components/video/Video';


function FileField({fileType, url, storageRef, maxSize, onSaving, onSaved, onCancel, ...props}) {
  const [percent, setPercent] = useState(0);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(url);
  const [show, setShow] = useState(false);
  const firebase = useFirebase().app;
  const storage = firebase.storage();
  
  const fieldName = props.name;
  const [, urlMeta, urlHelper] = useField(`${fieldName}URL`);
  const [, sizeMeta, sizeHelper] = useField(`${fieldName}Size`);
  maxSize = maxSize || 250; 

  useEffect(() => {
    setSaved(url);
  }, [url]);
  
  const fileTypes = {
    video: { 
      accept: 'video/mp4,video/x-m4v,video/*'
    },
    image: {
      accept: 'image/*'
    }
  }

  const handleChange = async (e) => {
    setPercent(0);
    setSaved(false);

    if(!e.target.files || !e.target.files[0]) {
      urlHelper.setValue(url);
      urlHelper.setTouched();
      sizeHelper.setValue(0);
      sizeHelper.setTouched();
      return;
    }

    const file = e.target.files[0];
    const size = file.size / 1024 / 1024  // in MB
    
    urlHelper.setValue(url);
    urlHelper.setTouched();
    sizeHelper.setValue(size);
    sizeHelper.setTouched();
    
    if (size < maxSize) {
      const ext = file.name.split('.').pop();
      const ref = storage.ref();
      const fileRef = ref.child(`${storageRef}.${ext}`);

      let metadata = {};
      metadata = {cacheControl: 'public,max-age=86400'};

      setSaving(true);
      fileRef.put(file, metadata)
      .on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {
        var percentComplete = (snapshot.bytesTransferred / snapshot.totalBytes * 100).toFixed(0);
        setPercent(percentComplete);
      }, () => setSaving(false), async () => {
        const url = await fileRef.getDownloadURL();
        urlHelper.setValue(url);
        setSaved(true); 
        setSaving(false);
      });
    }
  }

  const handleClose = () => setShow(false);

  return (
    <>
      <label htmlFor={props.name} className="col-sm-3 col-form-label text-left text-sm-right">
        {props.label}:
        {saved?<div className="text-success">Saved!</div>:<div className="text-danger">Not Saved</div>}
      </label>
      <div className="col-sm-9 files">
        {saving && 
          <div>
            <Spinner className="mr-1" animation="border" role="status" />
            <ProgressBar style={{minWidth: '20px'}} now={percent} label={`${percent}%`} variant="primary" />
          </div>
        }
        <Field {...props} accept={fileTypes[fileType].accept} onChange={handleChange} className="form-control-file"></Field>
        {props.tooltip&&<div className="text-left font-italic">{props.tooltip}</div>}
        {url && fileType==='video' && 
          <div className="text-left pt-1">
            <Button variant="outline-secondary" onClick={() => setShow(true)} className="mb-2"><FaVideo /> View Video</Button>
            <Modal show={show} onHide={handleClose} size="lg" centered>
              <Video src={url} />
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal>
          </div>
        }
        <div className="error_message">
          {urlMeta.error && urlMeta.touched && <ErrorMessage name={`${fieldName}URL`}  />}
          <br/>
          {sizeMeta.error && sizeMeta.touched && <ErrorMessage name={`${fieldName}Size`}  />}
        </div>
      </div>
    </>
  )
}

export default FileField;