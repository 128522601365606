import React, { useState } from 'react';
import ReactPlayer from 'react-player/file';
import { FaPlay } from 'react-icons/fa';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useEffect } from 'react';

function Video({src, onEnded, autoPlay, play, onPause, onSpeedChange, showSpeed="true"}) {
  autoPlay = autoPlay || false;
  play = play || false;
  const [playing, setPlaying] = useState(autoPlay);
  const [speed, setSpeed] = useState(1);
  
  const handlePause = () => {
    setPlaying(false);
    if (onPause) {
      onPause();
    }
  }

  const handleSpeedChange = (speed) => {
    setSpeed(speed);
    if (onSpeedChange) {
      onSpeedChange(speed);
    }
  }

  useEffect(() => {
    setPlaying(play);
  }, [play])

  return (
    <>
      <div className="embed-responsive embed-responsive-4by3">
        <div className="embed-responsive-item">
          <ReactPlayer 
            url={src} 
            controls 
            playbackRate={speed}
            playing={playing || play} 
            muted={true}
            onPlay={() => setPlaying(true)} 
            onPause={handlePause} 
            onEnded={onEnded}
            config={{tracks: [], attributes: {preload: 'none', poster: '/logo.svg'}}}
            />
          {
            !playing && 
            <div className="card-img-overlay d-flex h-100 justify-content-center">
              <FaPlay className="text-light align-self-center cursor_pointer" size="100px" onClick={() => setPlaying(!playing)}/>
            </div>
          }
        </div>
      </div>
      {showSpeed &&
        <div className="text-center p-1 bg-dark border-0">
          <ButtonGroup >
            <Button size="lg"  variant={speed===1?"primary":"secondary"} onClick={() => handleSpeedChange(1)} >1x</Button>
            <Button size="lg"  variant={speed===1.5?"primary":"secondary"} onClick={() => handleSpeedChange(1.5)} >1.5x</Button>
            <Button size="lg"  variant={speed===2?"primary":"secondary"} onClick={() => handleSpeedChange(2)} >2x</Button>
            <Button size="lg"  variant={speed===5?"primary":"secondary"} onClick={() => handleSpeedChange(5)} >5x</Button>
          </ButtonGroup>
        </div>
      }
    </>
  )
}

export default Video;