import React from 'react';
import { Accordion, Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useUser, USER_STATUS } from 'services/User';
import { getDatesFromWeekNumber } from 'services/Utilities'
import { FaCheckCircle, FaCircle, FaCaretDown, FaHome } from 'react-icons/fa';
import './SkillItem.css';
import SkillDetails from './SkillDetails';



function SkillItem ({skill}) {
  const { user } = useUser();

  let attempts = 0;
  if (Array.isArray(skill.attempts)) {
    attempts = skill.attempts.length;
  } else {
    skill.attempts = [];
  }
  const locationIcon = {
    Gym: <img alt="Gym" src="/WarpedWallIcon.svg" className="location_icon" />,
    Home: <FaHome alt="Home" className="location_icon" />,
    Monstro: <img alt="Monstro" src="monstroicon.png" width="30px" />
  };

  const SkillRow = ({skill, children}) => {
    return (
      <Row className="border-top-0">
        <Col xs="1" className="align-self-center">
          {locationIcon[skill.location]}
        </Col>
        <Col className="text-left">
          <Row>
            <Col>{skill.name}</Col>
          </Row>
          <Row>
            <Col>
              Active Weeks: 
              { skill.weeks.map(weekNumber => {
                const dates = getDatesFromWeekNumber(weekNumber);
                console.log(dates)
                if (Array.isArray(dates) && dates.length === 2) {
                  return (
                    <div>&nbsp;&nbsp;Week {weekNumber}: {dates[0]} - {dates[1]}</div>
                  )
                }
                return null;
              })}
            </Col>
          </Row>
          <Row>
            <Col className="small text-muted"><strong>Equipment:</strong> {skill.equipment.toString() || 'None'}</Col>
          </Row>
        </Col>
        {children}
      </Row>
    )
  }
  
  return (
    <Card className="border border-secondary mb-2 skill_item">
      <Accordion.Toggle as={Card.Header} eventKey={skill.id} className="p-0">
        {
          skill.attempts.includes(user.uid) ? 
            <Row className="bg-tertiary p-1 small border-bottom-0 text-light">
              <Col className="text-left"><FaCheckCircle/> Completed</Col>
              <Col className="text-right">
              {attempts} Competitor{attempts !== 1 && 's'}
              </Col>
            </Row> 
          : 
            <Row className="bg-gray p-1 small border-bottom-0">
            <Col className="text-left"><FaCircle/> Not Completed</Col>
            <Col className="text-right">
              {attempts} Competitor{attempts !== 1 && 's'}
            </Col>
          </Row>
        }
        <SkillRow skill={skill}>
          <Col xs="2"><FaCaretDown/></Col>
        </SkillRow>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={skill.id}>
        <div className="p-2">
          <SkillDetails skill={skill} />
          { user.status>1 &&
              <Card.Footer>
                <Button as={Link} variant="primary" to={`Skill/${skill.id}`} block>Attempt
                </Button>
              </Card.Footer>
          }
        </div>
      </Accordion.Collapse>
    </Card>
  );
};

export default SkillItem;  