import React, { useState, useCallback, useEffect } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { trackPromise } from 'react-promise-tracker';
import { useDebounce } from 'services/Utilities';
import { useFirebase } from 'services/Firebase';

function PromoCode ({onValid}) {
  const [promoCode, setPromoCode] = useState();
  const [message, setMessage] = useState('');
  const {logError, functions} = useFirebase();
  
  const debouncedPromoCode = useDebounce(promoCode, 1500);

  const handlePromoChange = (e) => {
    setPromoCode(e.target.value);
    setMessage();
  }

  const setError = useCallback((message) => {
    message = message || 'Error subscribing';
    setMessage(`${message}. If problem persists, please contact support.`);
    logError({error: message, info: '', page_location: 'Subscribe'});
    return;
  }, []);

  const getPlanId = useCallback(async (promoCode) => {
    if (promoCode) {
      const getPayPalPlanId = functions.httpsCallable('getPayPalPlanId');

      const result = await 
      getPayPalPlanId({promoCode: promoCode})
      .catch((err) => {
        // Getting the Error details.
        var message = err.message;
        setError(message);
      });

      if (!(result && result.data && result.data.planId)) {
        setMessage('Invalid Promo Code');
        return null;
      }

      setMessage('Success');
      onValid(result.data.planId);
    }
  }, [functions, setError]);

  useEffect(() => {
    trackPromise(getPlanId(debouncedPromoCode));
  }, [debouncedPromoCode]);
  

  return (
    <>
      <label className="form-label">Promo Code: </label>
      <input type="text" className="form-control" onChange={handlePromoChange} />
      {message && 
        (message === 'Success' ? 
          <div className="text-success"><FaCheckCircle /></div> 
          : 
          <div className="text-danger"><FaTimesCircle /> {message}</div>
        )
      }
    </>
  )
}

export default PromoCode;