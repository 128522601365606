import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useFirebase } from 'services/Firebase';
import { useHistory } from 'react-router-dom';
import { uuid, getSeasonId, getNext6Seasons } from 'services/Utilities';
import { trackPromise } from 'react-promise-tracker';
import DynamicForm from 'components/forms/DynamicForm';


function EditableSkill({id}) {
  const history = useHistory();
  const db = useFirebase().db;
  const [skill, setSkill] = useState();
  const seasonId = getSeasonId();
  const seasons = getNext6Seasons();

  //Set id to unique key if we are adding skill
  const skillId = id || uuid();

  //Keep the first value empty
  const locations = ["", "Overall", "Monstro"];
  let fields;
  
  //Get Skill and Equipment
  useEffect(() => {
    const getSkill = async () => {
      if (id && id !== '') {
        const result = await trackPromise(db.collection('skills').doc(id).get());
        if(result.exists) {
          const data = result.data();
          setSkill(data);
        }
      } else {
        setSkill({});
      }
    }
    getSkill();
  }, [id, db]);

  if (skill) {
    skill.equipmentText = skill.equipment && skill.equipment.toString();
    fields = [
      {
        name: 'name',
        label: 'Name',
        tooltip: 'Enter a descriptive name of the skill.',
        value: skill.name,
        as: 'input',
        type: 'text',
        validation: Yup.string()
          .max(30, 'Max length is 30')
          .required('*Required')
      },
      {
        name: 'location',
        label: 'Location',
        value: skill.location,
        as: 'select',
        options: locations,
        validation: Yup.string()
          .required('*Required')
      },
      {
        name: 'equipment',
        label: 'Equipment Needed',
        tooltip: 'Select the pieces of equipment needed for this skill, comma-separated',
        value: skill.equipmentText,
        as: 'input',
        type: 'text'
      },
      {
        name: 'description',
        label: 'Description',
        value: skill.description,
        as: 'textarea',
        validation: Yup.string()
          .required('*Required')
      },
      {
        name: 'videoTips',
        label: 'Video Tips',
        tooltip: 'Enter any tips about filming. (eg - Make sure your whole body is in view.)',
        value: skill.videoTips,
        as: 'textarea',
        validation: Yup.string()
          .max(1000, '*Max 1000 characters')
      },
      {
        name: 'rules',
        label: 'Skill Rules',
        tooltip: 'Include a list of the rules.  This will be one rule per line (eg - 1 Minute)',
        value: skill.rules,
        as: 'textarea',
        validation: Yup.string()
          .max(1000, '*Max 1000 characters')
          .required('*Required')
      },
      {
        name: 'seasonId',
        label: 'Season',
        tooltip: 'Season to start skill',
        value: skill.seasonId,
        as: 'select',
        options: seasons,
        validation: Yup.string()
          .required('*Required')
      },
      {
        name: 'weeks',
        label: 'Weeks',
        tooltip: 'Week(s) to include skill',
        value: skill.weeks,
        as: 'input',
        type: 'checkbox',
        options: ['1', '2', '3', '4', '5', '6'],
        validation: Yup.string()
          .required('*Required')
      },
      {
        name: 'video',
        label: 'Walkthrough Video',
        value: skill.videoURL,
        as: 'input',
        type: 'file',
        validation: Yup.string()
          .required('*Required'),
        fileType: 'video',
        storageRef: `walkthroughs/${skillId}`
      }
    ];
  }  
  
  const handleSubmit = (formValues) => {
    const values = {...formValues};
  
    const updateData = async () => {
      //remove video
      delete values['video'];
      delete values['videoSize'];
      
      //Check if skill is current
      values.equipment = values.equipment && values.equipment.split(/\s*,\s*/);
      const isCurrent = values.seasonId === seasonId;
      console.log(isCurrent);
      return db.runTransaction((transaction) => {
        transaction.set(db.collection('skills').doc(skillId), values);
        if (isCurrent) {
          const seasonSkill = {
            description: values.description,
            rules: values.rules,
            videoTips: values.videoTips,
            equipment: values.equipment,
            location: values.location,
            name: values.name,
            videoURL: values.videoURL,
            seasonId: seasonId,
            weeks: values.weeks,
            id: skillId
          }
          transaction.set(db.collection('seasons').doc(seasonId).collection('season_skills').doc(skillId), seasonSkill, {merge: true});
        }
        return Promise.resolve();
      });
    };
    updateData()
    .then(() => history.push('/Admin/Skill'));
  };

  const handleCancel = () => {
    history.push('/Admin/Skill');
  }

  if(!fields) return null;

  return (
    <>
      <DynamicForm fields={fields} onSubmit={handleSubmit} onCancel={handleCancel} />
    </>
  );
};

export default EditableSkill;