import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

function Timer ({duration, speed, onEnd, onStart}) {
  const [running, setRunning] = useState(false)
  const [seconds, setSeconds] = useState(duration);

  const handleReset = () => {
    setRunning(false);
    setSeconds(duration);
    onEnd();
  }

  const toggleRunning = () => {
    if (running) {
      onEnd()
    } else {
      onStart()
    }
    setRunning(!running);
  }

  useEffect(() => {
    let timer = null
    if (running && seconds > 0) {
      timer = setInterval(() => updateSeconds(timer), 1000 / speed);
    }
    const updateSeconds = () => {
      const newSeconds = seconds - 1;
      setSeconds(newSeconds);
      if (newSeconds === 0) {
        clearInterval(timer);
        onEnd();
      }
    }
    return () => clearInterval(timer);
  }, [running, seconds, onEnd, speed]);


  return (
    <>
      <ButtonGroup>
        <Button size="lg" onClick={toggleRunning} variant="primary">{running?'Stop':'Start'} Timer</Button>
        <Button size="lg" onClick={handleReset} variant="secondary">Restart Timer</Button>
      </ButtonGroup>
      <h2 className="p-3 m-1 bg-dark text-white">{seconds}</h2>
    </>
  )

}

export default Timer;