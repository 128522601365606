import React, { useCallback } from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useFirebase } from 'services/Firebase';
import PeriodDropdown from 'components/PeriodDropdown';

function AttemptVideoDropdown ({includeCurrent, uid, onChange, period, onlySeasons, preferOriginal}) {
  const db = useFirebase().db;
  preferOriginal = preferOriginal || false;

  const area = 'periodDropdown';
  const {promiseInProgress} = usePromiseTracker({area: area});
  
  const getAttempts = useCallback(async (period) => {
    if (!(period && period.seasonId && period.period)) return [];

    const attempts = [];
    let videoRef = db.collection('seasons').doc(period.seasonId)
      .collection('periods').doc(period.period)
      .collection('videos');
    
    if (uid) {
      videoRef = videoRef.where('uid', '==', uid);
    }

    const videoSnap = await videoRef.get()
      .catch(err => {throw new Error('Error retrieving videos: ' + err.message)})

    const promises = [];
    videoSnap.forEach(async (video) => {
      const videoData = video.data();
      let url = videoData.url;

      promises.push(
        videoData.attemptRef.get()
        .then(attemptDoc => {
          if (!attemptDoc.exists) throw new Error('Missing attempt');
      
          const attemptData = attemptDoc.data();
          delete attemptData.videoRef;

          if (!preferOriginal && videoData.convertedURL) {
            url = videoData.convertedURL;
          }
          const attempt = {
            url: url, 
            ...attemptData
          };
          attempts.push(attempt);
        })
      )
    })
    await Promise.all(promises).catch(err => {throw new Error('Error retrieving attempt: ' + err.message)});
    return attempts;
  }, [db, uid, preferOriginal]);

  const handleChange = useCallback(async (period) => {
    const attemptList = await trackPromise(getAttempts(period), area);
    onChange({attempts: attemptList, period: period});
  }, [getAttempts, onChange]);

  return (
    <>
      <PeriodDropdown includeCurrent={includeCurrent} onlySeasons={onlySeasons} period={period} onPeriodChange={handleChange} loading={promiseInProgress}/>
    </>
  )
}

export default AttemptVideoDropdown;