import React from 'react';
import Feed from "react-instagram-authless-feed";
import Error from 'components/ErrorBoundary';
import { FaInstagram } from 'react-icons/fa';


function InstagramFeed() {
  return (
    <>
      <Error>
        <a href="https://instagram.com/skillsleague.ninja" target="_blank" rel="noopener noreferrer"><FaInstagram style={{verticalAlign: 'text-bottom'}}/> @skillsleague.ninja</a>
        <Feed userName="skillsleague.ninja" className="Feed" classNameLoading="Loading" limit="8"/>
      </Error>
    </>
  )
}

export default InstagramFeed;