import React from 'react';
import { Formik, Form } from 'formik';
import FileField from 'components/forms/FileField';
import InputField from 'components/forms/InputField';
import CheckboxGroupField from 'components/forms/CheckboxGroupField';
import * as Yup from 'yup';


function DynamicForm({fields, onSubmit, onCancel, submitLabel}) {
  let validation = {};
  let initialValues = {};

  fields.forEach((field) => {
    if (field.type === 'file') {
      initialValues[`${field.name}URL`] = field.value || '';
      validation[`${field.name}URL`] = field.validation;
      //initialValues[`${field.name}Size`] = 0;
      //if (field.maxSize) {
      //  validation[`${field.name}Size`] = validation[`${field.name}Size`]
      //    .max(field.maxSize, '*Max size is 10MB');
      //}
    } else {
      initialValues[field.name] = field.value || '';
      validation[field.name] = field.validation;
    } 
    if (field.type === 'checkbox') {
      initialValues[field.name] = field.value || [];
    }
  });
  
  const getField = ({value, maxSize, ...field}) => {
    switch(field.type) {
      case 'file':
        return <FileField url={value} {...field} maxSize={maxSize} className="form-control"/>
      case 'checkbox':
        return (
          <CheckboxGroupField {...field} />
        )
      default: 
        return <InputField {...field} className="form-control"/>
    }
  }
  
  return (
    <Formik 
    initialValues={initialValues}
    validationSchema={Yup.object(validation)}
    onSubmit={onSubmit}
    enableReinitialize={true}
    >
    {props => 
      <Form>
        {fields.map((field) => {
          return (
            <div key={field.name}>
              <div className="form-group row">
                {getField(field, props)}
              </div>
            </div>
            );
        })}
        <br/>
        <div>
          <button type="submit" disabled={props.isSubmitting || !props.isValid} className="btn btn-primary mr-2">{submitLabel || 'Save'}</button>
          {onCancel && <button className="btn btn-light ml-2" onClick={onCancel}>Cancel</button>}
        </div>
      </Form>
    }
  </Formik>
  )
}

export default DynamicForm;