import React from 'react';
import { Card, Form } from 'react-bootstrap';

function SkillFilter(props) {
  return (
    <Card>
      <Card.Header className="pt-1 pb-1 bg-secondary text-light" bg="secondary">{props.title}</Card.Header>
      <Card.Body className="text-left pt-0">
        {props.list.map((item) =>
          <Form.Check key={item} type="checkbox" label={item} value={item} checked={props.filteredList.includes(item)} onChange={props.onChange}/>
        )}
      </Card.Body>
    </Card>
  )
}

export default SkillFilter;