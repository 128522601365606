import React from 'react';
import LeaderboardTable from './LeaderboardTable';
import MedalStatus from './MedalStatus';
import { Badge } from 'react-bootstrap';


function LeagueLeaderboard ({athletes, location, results=[], final, period, resultsFilters=[], prizes={}}) {
  let monthlyPrizePool = prizes.monthlyPool && prizes.monthlyPool.total;
  monthlyPrizePool = monthlyPrizePool || 0;
  let skillsPrizePool = prizes.skillsPool && prizes.skillsPool.total;
  skillsPrizePool = skillsPrizePool || 0;
  const formatPrize = (amount) => {
    if (typeof amount === 'undefined') return null;
    return '$'+amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  const prizePool = () => {
    return (
      <>
        <Badge variant="light">Monthly Prize Pool: {formatPrize(monthlyPrizePool)}</Badge><br/>
        <Badge variant="dark">Skills Prize Pool: {formatPrize(skillsPrizePool)}</Badge><br/>
      </>
    )
  }
  const prizeRow = (row) => {
    return (
      <>
        <MedalStatus uid={row.original.uid} athletes={athletes} results={results} />
        <Badge variant="light">{formatPrize(row.original.monthlyAmount)}</Badge><br/>
        <Badge variant="dark">{formatPrize(row.original.skillAmount)}</Badge>
      </>
    )
  }

  return (
    <LeaderboardTable athletes={athletes} location={location} final={final} results={results} period={period} resultsFilters={resultsFilters} prizePool={prizePool} prizeRow={prizeRow} />
  )
}

export default LeagueLeaderboard;