import React from 'react';
import { FaRegCalendarCheck, FaRegCalendarTimes } from 'react-icons/fa';
import { getSeasonId, getWeekNumber } from 'services/Utilities';
import { Image, Tooltip, OverlayTrigger } from 'react-bootstrap';

function SkillStatus ({period, skill={}}) {
  const seasonId = getSeasonId(); //Get current season
  const weekNumber = getWeekNumber(); //Get current week

  const active = (seasonId === period.seasonId) && (skill.weeks && Array.isArray(skill.weeks) && skill.weeks.includes(weekNumber))
  
  return (
    <>
      <div className="icon">
        <OverlayTrigger
          key={skill.name}
          trigger={['hover', 'click']}
          placement="left"
          overlay={
            <Tooltip>
              {skill.weeks && "Skill is active for weeks: " + skill.weeks.toString()}
            </Tooltip>
          }
        >
          {active ? <span className="text-success icon">ACTIVE &nbsp;<FaRegCalendarCheck /></span> : <div className="text-danger icon">CLOSED &nbsp;<FaRegCalendarTimes /></div>}
        </OverlayTrigger>
      </div>
    </>
  )
}

export default SkillStatus;