import React from 'react';
import {Route, Switch } from 'react-router-dom';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import UserProfile from 'pages/UserProfile';
import Home from 'pages/Home';
import AdminSkill from 'pages/admin/Skill';
import AdminReview from 'pages/admin/VideoReview';
import AdminUsers from 'pages/admin/Users';
import AdminViewAttempts from 'pages/admin/ViewAttempts';
import Skills from 'pages/Skills';
import Leaderboard from 'pages/Leaderboard';
import Help from 'pages/Help';
import FAQ from 'pages/FAQ';
import Join from 'pages/Join';
import HowItWorks from 'pages/HowItWorks';
import Welcome from 'pages/Welcome';
import TV from 'pages/TV';
import { useUser } from 'services/User';
import Privacy from 'pages/Privacy';
import TOS from 'pages/TOS';
import Athletes from 'pages/Athletes';
import GettingStarted from 'pages/GettingStarted';
import Passport from 'pages/Passport';


const routeSections = [
  {
    minLevel: 1, 
    adminLevel: 1, //Requires gym admin
    routes: [
      {
        path: '/Admin/Skill/:section?/:skillId?',
        component: AdminSkill
      },
      {
        path: '/Admin/Review',
        component: AdminReview
      },
      {
        path: '/Admin/Users',
        component: AdminUsers
      },
      {
        path: '/Admin/ViewAttempts',
        component: AdminViewAttempts
      }
    ]
  },
  {
    minLevel: 0, //Anyone can view
    routes: [ 
      {
        path: '/Login',
        component: Login
      },
      {
        path: '/Help',
        component: Help
      },
      {
        path: '/FAQ',
        component: FAQ
      },
      {
        path: '/HowItWorks',
        component: HowItWorks
      },
      {
        path: '/GettingStarted',
        component: GettingStarted
      },
      
      {
        path: '/Privacy',
        component: Privacy
      },
      {
        path: '/TOS',
        component: TOS
      },
      {
        path: '/Skills',
        component: Skills
      },
      {
        path: '/Welcome',
        component: Welcome
      },
      {
        path: '/Passport',
        component: Passport
      },
      {
        path: '/',
        component: Welcome
      }   
    ],
  },
  {
    minLevel: 1, //Requires signed in user
    routes: [ 
      {
        path: '/Logout',
        component: Logout
      },
      {
        path: '/Join',
        component: Join
      },
      {
        path: '/Skills',
        component: Skills
      },
      {
        path: '/User/:section?',
        component: UserProfile
      },
      {
        path: '/Home/:uid?',
        component: Home
      },
      {
        path: '/Passport',
        component: Passport
      },
      {
        path: '/',
        component: Welcome
      } 
    ]
  },
  {
    minLevel: 1.5, //Requires subscribed user
    routes: [ 
      {
        path: '/Passport',
        component: Passport
      },
      {
        path: '/',
        component: UserProfile
      } 
    ]
  },
  {
    minLevel: 1.5, //Requires active user
    routes: [ 
      {
        path: '/Home/:uid?',
        component: Home
      },
      {
        path: '/Leaderboard',
        component: Leaderboard
      },
      {
        path: '/Skill/:skillId?',
        component: Skills
      },
      {
        path: '/TV',
        component: TV
      },
      {
        path: '/Athletes',
        component: Athletes
      },
      {
        path: '/Passport',
        component: Passport
      },
      {
        path: '/',
        component: Home,
      }
    ]
  }
];

function getRoutes(user) {
  let routes = [];
  let status = user.status || 0;
  let adminStatus = user.adminStatus || 0;
  
  routeSections.forEach((section) => {
    const adminLevel = section.adminLevel || 0;
    if (section.minLevel <= status && adminLevel <= adminStatus ) {
      section.routes.forEach((route) => {
        const index = routes.findIndex((e) => e.path === route.path);
        if (index !== -1) {
          //remove item
          routes.splice(index, 1);
        }
        routes.push(route);
      })
    }
  });
  return routes;
}

function Routes() {
  const {user} = useUser();
  
  const routeList = getRoutes(user);
  const routes =  routeList.map((route, i) => {
    const exact = route.exact || false;
    return <Route path={route.path} component={route.component} key={i} exact={exact}/>
  });
  
  return (
    <Switch>
      { routes }
    </Switch>);
};
 
export default Routes;