import React from 'react';
import Avatar from 'components/users/Avatar';
import SortableTable from 'components/SortableTable';
import { useHistory } from 'react-router-dom';
import { getAge } from 'services/Utilities';
import { FaCheck, FaVideo } from 'react-icons/fa';
import './LeaderboardTable.css';
import GenderBadge from './GenderBadge';
import { Badge } from 'react-bootstrap';

function LeaderboardTable({athletes=[], location, results=[], final, period, resultsFilters=[], prizePool=()=>null, prizeRow=()=>null}) {
  const history = useHistory();

  results = results.filter(user => resultsFilters.every(filter => filter(user)));
  const data = React.useMemo(() => {
    console.log(results);
    console.log(athletes)
    return results;
  }, [results]);
  const columns = React.useMemo(() => [
    {
      Header: <div>
        <h4>{location}</h4>
        <span className="float-right text-right">
          {prizePool()}
        </span>
      </div>,
      id: {location},
      columns: [
        {
          Header: 'Rank',
          accessor: 'rank'
        },
        {
          Header: 'Image',
          accessor: 'uid',
          id: 'image',
          Cell: ({value}) => {
            const athlete = athletes[value];
            if (!athlete) return null;
            return <Avatar profile={athlete} />
          }
        },
        {
          Header: 'Name',
          accessor: 'uid',
          id: 'name',
          Cell: ({value}) => {
            const athlete = athletes[value];
            if (!athlete) return null;
            return athlete.displayName;
          }
        },
        {
          Header: 'Age',
          accessor: 'uid',
          id: 'age',
          Cell: ({value}) => {
            const athlete = athletes[value];
            if (!athlete) return null;
            return (
              <>
                <span>{getAge(athlete.birthday)}</span>
                &nbsp;
                <GenderBadge gender={athlete.gender} />
              </>
            )
          }
        },
        {
          Header: 'Points',
          accessor: 'total'
        },
        {
          Header: 'Prizes',
          accessor: 'uid',
          id: 'prizes',
          Cell: ({row, value}) => {
            const athlete = athletes[value];
            let ret = '';
            if (athlete && athlete.admin) {
              ret = <Badge variant="success">ADMIN</Badge>
            } else if (athlete && !athlete.subscribed) {
              ret = <Badge variant="warning">FREE</Badge>
        
            } else {
              ret = prizeRow(row)
            }
            
            return (
              <span className="float-right">
                {ret}
              </span>
            );
          }
        }
      ]
    }
  ], [athletes, results, location, prizePool, prizeRow])

  const handleClick = (row) => {
    history.push(`/Home/${row.original.uid}`, {period: period})
  }
  return (
    <>
      {final?
        <h4 className="text-success icon">FINAL&nbsp; <FaCheck /></h4>
      : 
        <h4 className="text-warning icon">UNDER REVIEW&nbsp; <FaVideo /></h4>
      }
      <SortableTable columns={columns} data={data} onRowSelect={handleClick} height="500px"/>
    </>
  )
}

export default LeaderboardTable;