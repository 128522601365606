import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useFirebase } from 'services/Firebase';
import { useUser } from 'services/User';
import { useHistory } from 'react-router-dom';
import { Prompt } from 'react-router';
import { getSeasonId } from 'services/Utilities';
import DynamicForm from 'components/forms/DynamicForm';
import { trackPromise } from 'react-promise-tracker';
import Loader from 'components/Loader';

function SkillAttemptForm({skill}) {
  skill = skill || {};
  const isCancelled = useRef(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const videoArea = 'video';
  const attemptArea = 'attempt';
  
  const {functions, logError} = useFirebase();
  const history = useHistory();
  const {user} = useUser();
  const [attempt] = useState({});
  const seasonId = getSeasonId();
  const skillId = skill.id;
  
  const maxSize = 200; //MB
  const fields = [
    {
      name: 'video',
      label: 'Video',
      as: 'input',
      type: 'file',
      validation: Yup.string()
        .required('*Required'),
      fileType: 'video',
      maxSize: maxSize,
      storageRef: `seasons/${seasonId}/Current/${user.uid}/temp_${skillId}`
    },
    {
      name: 'reps',
      label: 'Reps',
      tooltip: 'The number of successful repititions of this skill within the time limit.',
      value: attempt.reps,
      as: 'input',
      type: 'number',
      validation: Yup.number()
        .min(0, 'Minimum number of reps is 0')
        .max(999, 'Maximum number of reps is 999')
        .required('*Required')
    }
  ];
  
  //Cancel form submission if we unmount component
  useEffect(() => {
    return () => isCancelled.current = true;
  }, []);
  
  const handleSubmit = (formValues) => {
    const values = {...formValues};
  
    const updateData = async () => {
      values.location = skill.location;
      delete values['video'];
      delete values['videoSize'];

      const attempt = {
        uid: user.publicProfile.uid,
        photoURL: user.publicProfile.photoURL || '',
        birthday: user.publicProfile.birthday,
        displayName: user.publicProfile.displayName,
        champ: user.publicProfile.champ || '',
        admin: user.publicProfile.admin || false,
        subscribed: user.publicProfile.subscribed,
        skillName: skill.name,
        skillId,
        ...values
      };
      const saveAttempt = functions.httpsCallable('saveAttempt');
      await trackPromise(saveAttempt({attempt: attempt})
      .catch((err) => {
        // Getting the Error details.
        var message = err.message;
        var details = err.details;
        logError({error: message, info: details, page_location: 'Save Attempt'});
      }), attemptArea);
      
      //Update skill
      if(!isCancelled.current) {
        setSubmitted(true);
        history.push('/Skills');
      }
    };
    updateData();
  };
  
  const handleCancel = () => {
    history.push('/Skill');
  }

  return (
    <>
      <Prompt
        when={!isSubmitted}
        message='You have not saved your attempt, are you sure you want to leave?'
      />
      <Loader message="Saving Video" area={videoArea} fullScreen="true" />
      <Loader message="Saving Attempt" area={attemptArea} fullScreen="true" />
      <DynamicForm fields={fields} onSubmit={handleSubmit} onCancel={handleCancel} submitLabel={'Save Attempt'} />
    </>
  );
};

export default SkillAttemptForm;