import React, {useEffect, useState} from 'react';
import { useFirebase } from 'services/Firebase';
import SortableTable from 'components/SortableTable';
import { trackPromise } from 'react-promise-tracker';
import { DateTime } from 'luxon';
import PeriodDropdown from 'components/PeriodDropdown';


function Passport () {
  const db = useFirebase().db;
  const [users, setUsers] = useState();
  const [points, setPoints] = useState();
  const [period, setPeriod] = useState();


  useEffect(() => {
    const getData = async () => {
      const userSnap = await db.collectionGroup('athletes').where('passportName', '>', '').get();
      setUsers(userSnap.docs.map(doc => {
        const data = doc.data();
        return data
      }));
    }

    trackPromise(getData());
  }, [db]);

  useEffect(() => {
    const getData = async () => {
      users.forEach(user => user.points = 0);
      const boardSnap = await db.collection('seasons').doc(period.seasonId)
        .collection('periods').doc('Month')
        .collection('leaderboards')
        .get()
        .catch(console.error);
      boardSnap.forEach(doc => {
        if (doc.id === 'Home' || doc.id === 'Gym') {
          const data = doc.data();
          users.forEach(user => {
            user.points = user.points || 0;
            const result = data.results.find(entry => entry.uid === user.uid);
            if (result) {
              user.points += result.total;
            }
          })
        }
      })
      setPoints([]);
      setPoints(users)
    }

    trackPromise(getData());
  }, [users, period]);

  const data = React.useMemo(() => {
    if (Array.isArray(points)) {
      return points;
    }
    return [];
  }, [points, period]);
  const columns = React.useMemo(() => [
    {
  
      Header: 'User Name',
      accessor: 'passportName'
    },
    {
      Header: 'Points',
      accessor: 'points'
    }
  ], [])

  return (
    <>
      <PeriodDropdown seasonsOnly={true} period={period} showCurrent={false} onPeriodChange={setPeriod} monthEndOnly={true} />
      <SortableTable columns={columns} data={data} /> 
    </>
  )
}

export default Passport;