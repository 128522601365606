import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './custom.scss';
import './index.css';
import { firebase, FirebaseContext } from 'services/Firebase';
import { BrowserRouter } from 'react-router-dom';
import { UserContextProvider } from 'services/User';
import Error from 'components/ErrorBoundary';
import { MessageContextProvider } from 'services/Messaging';

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={firebase}>
      <Error>
        <UserContextProvider>
          <MessageContextProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </MessageContextProvider>
        </UserContextProvider>
      </Error>
    </FirebaseContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
