import React from 'react';
import LoginContainer from 'components/users/LoginControl'

function Login() {
  return (
    <>
      <h3>Please Sign In</h3>
      <LoginContainer />
    </>
  );
};

export default Login;
