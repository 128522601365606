import React from 'react'
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import EditableProfile from 'components/users/EditableProfile';
import ViewProfile from 'components/users/ViewProfile';
import './UserProfile.css';

const sections = {
  Edit: {
    header: 'Edit Profile',
    component: EditableProfile
  },
  View: {
    header: 'View Profile',
    component: ViewProfile
  }
}

function UserProfile() {
  let {section} = useParams();
  section = section || 'View'
  const Section = sections[section].component;
  
  return (
    <>
      <h2>{sections[section].header}</h2>
      <Section />
    </>
  );
}

export default UserProfile;