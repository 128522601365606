import React, { useMemo } from 'react';
import DynamicForm from 'components/forms/DynamicForm';
import * as Yup from 'yup';
import { useFirebase } from 'services/Firebase';
import { useMessaging } from 'services/Messaging';
import { Season } from 'services/Utilities';


function VideoReviewForm ({item}) {
  item = item || {};
  const {db, logError} = useFirebase();

  const { sendMessage } = useMessaging();

  const fields = useMemo(() => {
    return [
      {
        name: 'reps',
        label: 'Reps',
        value: item.reps,
        as: 'input',
        type: 'number',
        validation: Yup.string()
          .required('*Required')
      },
      {
        name: 'feedback',
        label: 'Feedback',
        value: item.feedback,
        as: 'input',
        type: 'text'
      }
    ];
  }, [item]);

  const handleSubmit = async (formValues) => {
    const attemptRef = db.collection('seasons').doc(item.seasonId)
    .collection('periods').doc(item.period)
    .collection('attempts').doc(`${item.uid}_${item.skillId}`);

    attemptRef.set({reviewed: true, ...formValues}, {merge: true})
    .catch((err) => {
      console.error(err)
      // Getting the Error details.
      var message = err.message;
      var details = err.details;
      logError({error: message, info: details, page_location: 'Video Review'});
    });


    //If this is not month or current, also update current if the same video
    let currentDoc; 
    if (item.period !== 'Month' && item.period !== 'Current') {
      currentDoc = await db.collection('seasons').doc(item.seasonId)
      .collection('periods').doc('Current')
      .collection('attempts').doc(`${item.uid}_${item.skillId}`).get();

      if (currentDoc.exists) {
        const data = currentDoc.data();
        if (data.modifiedDate == item.modifiedDate) {
          //Same attempt, update review
          currentDoc.ref.set({reviewed: true, ...formValues}, {merge: true});
        }
      }
    }
    

    let message = `${Season({seasonId: item.seasonId})} - ${item.period} - ${item.skillName}: `
    
    if (formValues.feedback) {
      message = message + ` Reps changed to ${formValues.reps}. ${formValues.feedback}`
    } else {
      message = message + ` Successfully reviewed.  No Changes`;
    }

    sendMessage({
      uid: item.uid,
      title: 'Video Review',
      message: message});
  };
  
  return (
    <>
      <DynamicForm fields={fields} onSubmit={handleSubmit} submitLabel="Mark as Reviewed"/>
    </>
  )
}

export default VideoReviewForm