import React, { useContext, useEffect, useState } from 'react';
import { useFirebase } from 'services/Firebase';
import { useUser } from 'services/User';

export const MessageContext = React.createContext(null);
export const useMessaging = () => {
  return useContext(MessageContext);
};

export const MessageContextProvider = ({children}) => {
  const [messages, setMessages] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const {db, logError} = useFirebase();
  const FieldValue = useFirebase().app.firestore.FieldValue;
  const { user } = useUser();
  const uid = user && user.uid;

  

  useEffect(() => {
    const getData = async () => {
      const doc = await db.collection('users').doc(uid).collection('public').doc('messages').get();
      if (!doc.exists) return;
      
      const data = doc.data();
      if (!(Array.isArray(data.messages) && data.messages.length)) return;

      const messageList = data.messages.sort((a, b) => b.receivedDate - a.receivedDate);
      messageList.forEach((message, i) => message.id = i)
      handleMessageChange(messageList);
    }

    if (uid) {
      getData();
    }
  }, [db, uid])

  useEffect(() => {
    const getData = async () => {
      const doc = await db.collection('static').doc('notifications').get();
      if (!doc.exists) return;
      
      const data = doc.data();
      if (!(Array.isArray(data.notifications) && data.notifications.length)) return;

      const notificationList = [];
      data.notifications.forEach((notification) => {
        if (!(notification.startDate && typeof notification.startDate.toDate == 'function')) return false;
        if (!(notification.endDate && typeof notification.endDate.toDate == 'function')) return false;

        const startDate = notification.startDate.toDate();
        const endDate = notification.endDate.toDate();
        const today = Date.now();
        if (startDate <= today && endDate >= today) {
          notificationList.push(notification);
        }
      });
      setNotifications(notificationList);
    }

    getData();
  }, [db])

  const handleMessageChange = (messages) => {
    const unreadList = messages.filter((message) => {
      return !message.read;
    });
    setMessages(messages);
    setUnreadMessages(unreadList);
  }

  const sendMessage = async ({uid, ...message}) => {
    if (!(message && uid && message.title && message.message)) return null;

    const today = new Date();
    message.receivedDate = today;
    message.read = false;
    return await db
      .collection('users')
      .doc(uid)
      .collection('public').doc('messages')
      .set({messages: FieldValue.arrayUnion(message)}, {merge: true})
      .catch((err) => logError({error: err, page_location: 'Messaging', info: 'toggle read'}))
  }

  return (
    <MessageContext.Provider value={
      {
        notifications: notifications,
        messages: messages, 
        unreadMessages: unreadMessages, 
        setMessages: handleMessageChange,
        sendMessage: sendMessage
      }
    }>
      {children}
    </MessageContext.Provider>
  );
};