import React, {useEffect, useState} from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useFirebase } from 'services/Firebase';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Loader from 'components/Loader';

function LocationStats({uid, period}) {
  const [ranks, setRanks] = useState();
  const {db} = useFirebase();
  const area = 'locationStats';
  const {promiseInProgress} = usePromiseTracker({area: area});

  const locations = ['Gym', 'Home'];
  
  useEffect(() => {
    const getData = async () => {
      //Get Leaderboard
      const seasonId = period.seasonId;
      const periodName = period.period;
      const rankList = {};
      for (var location of ['Home', 'Gym']) {
        const boardDoc = await db.collection('seasons').doc(seasonId)
          .collection('periods').doc(periodName)
          .collection('leaderboards').doc(location)
          .get()
          .catch(err => {throw new Error('Error retrieving leaderboard: ' + err.message)});

        if (boardDoc.exists) {
          const data = boardDoc.data();
          if (!(Array.isArray(data.results) && data.results.length)) continue;

          const competitors = data.results.length;
          const userResult = data.results.find((user) => user.uid === uid);
          if (userResult) {
            rankList[location] = {competitors: competitors, ...userResult};
          }
        } 
      }
      setRanks(rankList); 
    }
    trackPromise(getData(), area);
  }, [uid, period])

  if (promiseInProgress) return <Loader area={area} size="sm"/>
  return (
    <Row>
    {
      locations.map((location) => {
        const hasRank = ranks && ranks[location];
        return (
          <Col key={location} xs={locations.length===2?6:4} className="p-0">
            <Card className={hasRank?'bg-primary text-light':'bg-light text-dark'}>
              <Card.Header>{location}</Card.Header>
              <Card.Body className="bg-light text-dark">
                {
                  hasRank?
                    <h4>#{ranks[location].rank} <small>/{ranks[location].competitors}</small>
                      <div className="small">{ranks[location].total} {ranks[location].total===1?'Pt.':'Pts.'}</div>
                    </h4>
                  :
                    <h4>N/A
                    <div className="small">0 Pts.</div>
                  </h4>
                }
              </Card.Body>
            </Card>
          </Col>
        )
      })
    }
    </Row>
  )
}

export default LocationStats;