import React from 'react';
import Avatar from 'components/users/Avatar';
import { useMessaging } from 'services/Messaging';


function HeaderAvatar({profile}) {
  profile = profile || {};

  const { unreadMessages } = useMessaging();
  const count = unreadMessages.length;

  return (
    <>
      <div className="notification_item">
        <span className="notification_badge bg-info">
          {count}
        </span>
        <Avatar profile={profile} />
      </div>
      
    </>
  )
}

export default HeaderAvatar;