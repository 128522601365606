import React, { useState, useCallback } from 'react';
import { Container, Modal, FormCheck, Button } from 'react-bootstrap';
import { useUser} from 'services/User';
import { useFirebase } from 'services/Firebase';
import { useHistory } from 'react-router-dom';
import { trackPromise } from 'react-promise-tracker';
import PayPalButtons from 'components/users/PayPalButtons';
import PromoCode from 'components/users/PromoCode';
import Loader from 'components/Loader';
import TOS from 'pages/TOS';

const Subscribe = () => {
  const history = useHistory();
  const {user, dispatch} = useUser();
  const {db, logEvent, logError, app} = useFirebase();
  const [show, setShow] = useState(false);
  const [ageChecked, setAgeChecked] = useState(false);
  const [tcChecked, setTcChecked] = useState(false);
  const [usePaypal, setUsePaypal] = useState(true);
  
  const [planId, setPlanId] = useState('P-0T97750141229400KL3ZBREA'); //Default Plan Id
  const [errorMessage, setErrorMessage] = useState('');
  
  const setError = useCallback((message) => {
    message = message || 'Error subscribing';
    setErrorMessage(`${message}. If problem persists, please contact support.`);
    logError({error: message, info: '', page_location: 'Subscribe'});
    return;
  }, []);

  const setSubscription = async (subscriptionID) => {
    const batch = db.batch();
    const userRef = db.collection('users').doc(user.authId).collection('athletes').doc(user.uid);
    batch.set(userRef.collection('private').doc('billing'), {subscriptionID: subscriptionID, 
      subscriptionStartDate: app.firestore.Timestamp.now()});
    batch.set(userRef, {subscribed: true, updated: true}, {merge: true})
    await batch.commit()
    .catch(err => {
      console.error(err);
      setError();
      logError({error: err, info: 'Updating Subscription', page_location: 'Subscribe'});
      return;
    });

    user.publicProfile.subscribed = true;
    await dispatch({type: 'update', payload: user});
    logEvent({type: 'purchase', 'transaction_id': subscriptionID});
    history.push('/User/Edit');
  };

  const handlePromo = (planId) => {
    if (!planId) return null;

    //Check if this is a paypal promo
    setUsePaypal(planId.startsWith('P-'));
    setPlanId(planId);
  }

  return (
    <Container className="mt-3">
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
      <div className="text-left">
        <PromoCode onValid={handlePromo} />
        <br/>
        <FormCheck>
          <FormCheck.Input checked={ageChecked} onChange={() => setAgeChecked(!ageChecked)} />
          <FormCheck.Label>Are you 18 or older?</FormCheck.Label>
        </FormCheck>
        <FormCheck>
          <FormCheck.Input checked={tcChecked} onChange={() => setTcChecked(!tcChecked)} />
          <FormCheck.Label>Do you agree with the <button className="btn btn-link p-0" onClick={() => setShow(true)}>Terms &amp; Conditions</button>?</FormCheck.Label>
        </FormCheck>
      </div>
      <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
        <TOS />
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal>

      {
        ageChecked && tcChecked && planId && usePaypal &&
        <>
          <PayPalButtons planId={planId} onApprove={(id) => trackPromise(setSubscription(id))} />
          <br/>
          <p className="font-italic text-muted small">
            *Subscriptions are managed through PayPal.  Credit Card information is not stored. Cancel at any time.
          </p>
        </>
      }
      {
        ageChecked && tcChecked && planId && !usePaypal &&
        <Button variant="primary" onClick={() => setSubscription(planId)}>Get Started</Button>
      }
    </Container>
  );
};
export default Subscribe;