import React, { useEffect, useState, useCallback } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Season, getSeasonId } from 'services/Utilities';
import { useFirebase } from 'services/Firebase';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import Loader from 'components/Loader';


function PeriodDropdown ({includeCurrent, period, onlySeasons, onPeriodChange, monthEndOnly=false}) {
  const [periods, setPeriods] = useState();
  const db = useFirebase().db;
  const area = 'periodDropdown';
  const { promiseInProgress } = usePromiseTracker({area: area});
  
  const handlePeriodChange = useCallback((period) => {
    if (onPeriodChange) {
      onPeriodChange(period);
    }
  }, [onPeriodChange]);

  useEffect(() => {
    let periodList = [];
      
    const getPeriods = async () => {  
      const periodDoc = await db.collection('static').doc('seasons').get();
      if (periodDoc.exists) {
        const data = periodDoc.data();
        
        data.periods.sort((a, b) => {
          if (a.seasonId < b.seasonId) return 1;
          if (a.seasonId > b.seasonId) return -1;
          if (a.period === 'Month') return -1;
          if (b.period === 'Month') return 1;
          if (a.period < b.period) return 1;
          return -1;
        });
        periodList.push(...data.periods);
      }
      
      if (onlySeasons && onlySeasons.length) {
        //Filter the seasons not in this list
        periodList = periodList.filter((item) => {
          return onlySeasons.includes(item.seasonId);
        })
      }
      if (monthEndOnly) {
        periodList = periodList.filter(item => item.period === 'Month');
      }
      
      setPeriods(periodList);
      if (!period && periodList.length) {
        handlePeriodChange(periodList[0]);
      } else {
        handlePeriodChange(period);
      }
    }

    //Start populating if period is Current
    if (includeCurrent) {
      const currentPeriod = {seasonId: getSeasonId(), period: 'Current'}
      periodList.push(currentPeriod);
    }
    trackPromise(getPeriods(), area);
  }, [db, includeCurrent, onlySeasons, handlePeriodChange, period])

  
  const PeriodName = ({period}) => {
    if (!period) return null;
    return `${Season(period)} - ${period.period}`;
  }

  if (promiseInProgress) return <Loader area={area} size="sm"/>
  if (!(Array.isArray(periods) && periods.length && period)) return null
  return (
    <Dropdown>
      <Dropdown.Toggle variant="secondary" id="periodList">
        <PeriodName period={period}/>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {periods.map((item, i) => {
          return <Dropdown.Item key={i} onClick={() => handlePeriodChange(item)}><PeriodName period={item}/></Dropdown.Item>
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default PeriodDropdown;