import React, {useState, useRef, useEffect, useMemo} from 'react';
import { useFirebase } from 'services/Firebase';
import Loader from 'components/Loader';
import SortableTable from 'components/SortableTable';
import { useHistory } from 'react-router-dom';


function EditableSkillList() {
  const db = useFirebase().db;
  const history = useHistory();
  
  const loading = useRef(false);
  const isCancelled = useRef(false);

  const [skills, setSkills] = useState([]);
  
  useEffect(() => {
    const getSkills = async () => {
      loading.current = 'Loading';
      let skillList = [];
      const snapshot = await db.collection('skills')
        .get()
        .catch(err => {throw new Error(err)})
      snapshot.forEach((doc) => skillList.push(Object.assign({id: doc.id}, doc.data())));
      console.log(skillList)
      if (!isCancelled.current) {
        loading.current = false;
        setSkills(skillList);
      }
    }
    getSkills();
    return () => isCancelled.current = true;
  }, [db]);

  const columns = useMemo(() => [
    {
      Header: 'Location',
      accessor: 'location'
    },
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Season',
      accessor: 'seasonId'
    },
    {
      Header: 'Weeks',
      accessor: 'weeks',
      Cell: ({value}) => value ? value.toString() : ""
    }
  ], [])
  const data = React.useMemo(() => {
    return skills;
  }, [skills]);

  const handleClick = (row) => {
    history.push(`/Admin/Skill/Edit/${row.original.id}`);
  };

  return (
    <>
      <Loader show={loading.current} message={loading.current} fullScreen="false"/>
      { skills.length!==0?
        <SortableTable columns={columns} data={data} onRowSelect={handleClick} />
        :
        <h4>No available skills</h4>
      }
    </>
  )
}

export default EditableSkillList;