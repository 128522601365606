import React from 'react'
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import EditableSkill from 'components/skills/EditableSkill';
import EditableSkillList from 'components/skills/EditableSkillList';

function UserInfo() {
  let {section, skillId} = useParams();
  
  const sections = {
    Add: {
      header: 'Add Skill',
      component: EditableSkill
    },
    Edit: {
      header: 'Edit Skill',
      component: EditableSkill
    },
    View: {
      header: 'View Skills',
      component: EditableSkillList
    }
  }
  
  section = section || 'View';
  if (section === 'Edit' && !skillId) {
    section = 'Add'
  }
  const Section = sections[section].component;
  
  return (
    <Container>
      <h2>{sections[section].header}</h2>
      <Section key={skillId} id={skillId} />
    </Container>
  );
}

export default UserInfo;