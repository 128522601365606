import React from 'react';
import { FaMedal } from 'react-icons/fa';
import { getAge } from 'services/Utilities';

function MedalStatus ({uid, athletes, results}) {
  if (!(uid && athletes && Array.isArray(results))) return null;

  const getAgeGroup = (age) => {
    if (age <= 10) return '0-10';
    if (age >= 11 && age <= 14) return '11-14';
    if (age >= 15 && age <= 39) return '15-39';
    return '40+';
  }

  //Only included subscribed users
  console.log(results)
  results = results.filter(user => user.subscribed===true);
  console.log(results)
  const athlete = athletes[uid] || {};
  athlete.age = getAge(athlete.birthday);
  athlete.ageGroup = getAgeGroup(athlete.age);

  const result = results.find(entry => entry.uid === uid);
  if (!result) return null;

  const overallStatus = result.rank === 1 && results.length >= 5;
  const genderResult = results.filter(entry => entry.gender === athlete.gender && !entry.admin);
  const overallGenderStatus = genderResult.length >=5 && genderResult[0].uid === uid;

  const ageGroupResult = results.filter(entry => {
    const ageGroup = getAgeGroup(getAge(entry.birthday));
    return ageGroup === athlete.ageGroup && !entry.admin;
  });
  const overallAgeGroupStatus = ageGroupResult.length >= 5 && ageGroupResult[0].uid === uid;

  const ageGroupGenderResult = results.filter(entry => {
    const ageGroup = getAgeGroup(getAge(entry.birthday));
    return ageGroup === athlete.ageGroup && !entry.admin && entry.gender === athlete.gender;
  })
  const ageGroupGenderStatus = ageGroupGenderResult.length >= 5 && ageGroupGenderResult[0].uid === uid;

  if (overallStatus || overallGenderStatus || overallAgeGroupStatus || ageGroupGenderStatus) return <div><FaMedal /></div>

  return null;
}

export default MedalStatus;