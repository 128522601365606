import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import { useMessaging } from 'services/Messaging';


const Notifications = ({types}) => {
  const [show, setShow] = useState();
  const { notifications } = useMessaging();
  const [ filteredNotifications, setFilteredNotifications] = useState();
  
  useEffect(() => {
    if (Array.isArray(notifications) && Array.isArray(types)) {
      const filteredList = notifications.filter(notification => {
        return types.includes('all') || types.includes(notification.type);
      })
      setFilteredNotifications(filteredList);
    }
  }, [types])

  const Notification = ({key, info, ...props}) => {
    const title = info.title;
    const message = info.message;
    return (
      <>
        <Alert {...props} key={title}>
          {title} {message && <div><Alert.Link href="#" onClick={() => setShow(title)} className="text-muted small">(click for more)</Alert.Link></div>}
        </Alert>
        {
          message && 
          <Modal size="lg" show={show===title} onHide={() => setShow(false)}>
            <Modal.Header closeButton className="bg-info text-white">
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body><div dangerouslySetInnerHTML={{__html: message}}></div></Modal.Body>
          </Modal>
        }
      </>
    )
  }

  if (!Array.isArray(filteredNotifications)) return null;

  return (
    <>
      {
        filteredNotifications.map((notification, i) => {
          let className = '';
          let variant = 'info';
          if (i < notifications.length) {
            className = 'mb-0';
          }
          if (i%2 === 1) {
            variant = 'secondary';
          } 
          
          return (
            <Notification key={i} info={notification} className={className} variant={variant} />
          );
        })
      }
    </>
  )
}

export default Notifications;