import React, { useState, useContext, useCallback, useEffect, useRef }  from 'react';
import { useFirebase } from 'services/Firebase';

export const USER_STATUS = {
  SIGNED_OUT: 0,
  SIGNED_IN: 1,
  REGISTERED: 1.5,
  SUBSCRIBED: 2,
  ACTIVE: 3
};
export const ADMIN_STATUS = {
  NO_ADMIN: 0,
  GYM_ADMIN: 1,
  LEAGUE_ADMIN: 2,
  APP_ADMIN: 98
}  
export const RESULTS = {
  ERR_INPUT: -1,
  ERR_PAYPAL: -2,
  ERR_DB: -3,
  ERR_USER: -4
}; 
const defaultUser = {
  status: USER_STATUS.SIGNED_OUT,
  adminStatus: ADMIN_STATUS.NO_ADMIN
};

export const UserContext = React.createContext(null);
export const useUser = () => {
  return useContext(UserContext);
};

export const UserContextProvider = ({children}) => {
  const {functions, logError} = useFirebase();
  const [user, setUser] = useState(defaultUser);
  const isCancelled = useRef(false);

  useEffect(() => {
    return () => isCancelled.current = true;
  })

  
  const dispatch = useCallback(async (action) => {
    async function getUser() {
      return await functions
        .httpsCallable('checkUser')
        .call({})
        .catch((err) => {
          // Getting the Error details.
          var message = err.message;
          var details = err.details;
          logError({error: message, info: details, page_location: 'User'});
          throw new Error('Trouble verifying user. Please try again or contact support if problem persists.');
        });
      ;
    };

    function assignStatus(user) {
      //Find active athlete
      if (!user) {
        throw new Error('No user. Try refreshing.');
      }
      if (!user.publicProfile) {
        user.status = USER_STATUS.SIGNED_OUT;
        return;
      }

      user.status = USER_STATUS.SIGNED_IN;
      if (user.publicProfile.displayName && user.publicProfile.subscribed) {
        user.status = USER_STATUS.ACTIVE;
      } else if (user.publicProfile.subscribed) {
        user.status = USER_STATUS.SUBSCRIBED;
      } else if (user.publicProfile.displayName) {
        user.status = USER_STATUS.REGISTERED;
      }
      user.adminStatus = user.publicProfile.adminStatus;
    }

    async function handleStateChange(authUser) {
      let user = defaultUser;
      if (authUser) {
        const result = await getUser(user);
        
        if(!result || !result.data) {
          throw new Error('Error checking user.');
        }
        
        const data = result.data;
        if (data.user) {
          user = data.user;
          assignStatus(user);
          return user;
        } 
        
        switch(data.status) {
          case RESULTS.ERR_DB:
          case RESULTS.ERR_INPUT:
          case RESULTS.ERR_PAYPAL:
          case RESULTS.ERR_USER:
            throw new Error(data.message);
          default:
            throw new Error('Can\'t validate user.');
        }
      }
    };

    function switchAthletes({user, uid}) {
      user.uid = uid;
      user.publicProfile = user.athletes.find(athlete => athlete.uid === uid);
      return user;
    }

    function addAthlete({user, uid}) {
      user.athletes.push({uid: uid, publicProfile: {uid: uid}});
      return switchAthletes({user: user, uid: uid});
    }

    async function refreshUser({activeUid}) {
      const refresh = functions.httpsCallable('refreshUser');

      const result = await refresh({activeUid: activeUid})
      .catch((err) => {
        // Getting the Error details.
        var message = err.message;
        var details = err.details;
        logError({error: message, info: details, page_location: 'User'});
        throw new Error('Trouble refreshing user. Please try again or contact support if problem persists.');
      });
      
      if(!result || !result.data) {
        throw new Error('Error refreshing user.');
      }
      const data = result.data;
      const user = data.user;
      if (user) {
        return user;
      } 
      switch(data.status) {
        case RESULTS.ERR_DB:
        case RESULTS.ERR_INPUT:
        case RESULTS.ERR_PAYPAL:
        case RESULTS.ERR_USER:
          throw new Error(data.message);
        default:
          throw new Error('Can\'t validate user.');
      }
    }
    
    var result = {};
    switch (action.type) {
      case 'get': //refresh all skills
        result = await getUser(action.payload);
        break;
      case 'refresh':
        result = await refreshUser(action.payload);
        break;
      case 'update':
        result = action.payload;
        break;
      case 'stateChange':
        result = await handleStateChange(action.payload);
        result.lastSync = new Date();
        break;
      case 'switchAthletes':
        result = switchAthletes(action.payload);
        break;
      case 'addAthlete':
        result = addAthlete(action.payload);
        break;
      case 'reset':
        result = defaultUser;
        break;
      default: 
        throw new Error('No Action Type');
    }
    assignStatus(result);
    localStorage.setItem('nsl.user', JSON.stringify(result));
    setUser(result);
    return result;
  }, [functions]);
  
  
  return (
    <UserContext.Provider value={{user, dispatch}}>
      {children}
    </UserContext.Provider>
  );
};