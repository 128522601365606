import React, {useState, useEffect, useCallback} from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';


function LeaderboardToggle({locations, visibleLocations, onChange}) {
  //const locations = ['Gym', 'Home', 'Monstro', 'Skills'];
  locations = locations || [];
  visibleLocations = visibleLocations || [];
  
  
  return (
    <ToggleButtonGroup type="checkbox" value={visibleLocations} onChange={onChange}>
      {locations.map((location) => (
        <ToggleButton key={location} value={location}>{location}</ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}

export default LeaderboardToggle;