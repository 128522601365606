import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './Flair.css';


const items = {
  top1Skills: {
    name: '# of 1st Place Finishes in a Skill',
    src: '/badge_toponeskill.svg'
  },
  top10Skills: {
    name: '# of Top 10 Finishes in a Skill',
    src: '/badge_toptenskill.svg'
  },
  top1Seasons: {
    name: '# of 1st Place Finishes in a Season',
    src: '/badge_toponeseason.svg'
  },
  top10Seasons: {
    name: '# of Top 10 Finishes in a Season',
    src: '/badge_toptenseason.svg'
  },
  seasons: {
    name: '# of Seasons',
    src: '/badge_seasons.svg'
  },
  skills: {
    name: '# of Skills',
    src: '/badge_skills.svg'
  },
  champBeater: {
    name: '# of Times you beat a reigning champ',
    src: '/badge_champbeater.svg'
  }
}
function FlairItem({badge, count}) {
  if (!count) return null;
  if (!badge) return null;

  return (
    <>
    <OverlayTrigger
        placement="top"
        trigger={['hover', 'click']}
        overlay={
          <Tooltip>
            {badge.name}
          </Tooltip>
        }
      >
        <div className="flair_item">
          <span className="flair_badge bg-primary">{count}</span>
          <img alt={badge.name} src={badge.src} className="flair" />
        </div>
      </OverlayTrigger>
    </>
  )
}

function Flair({flair}) {
  if (!flair) return null;

  const flairList = Object.entries(flair).map(([key, value]) => {
    return <FlairItem key={key} badge={items[key]} count={value} />
  })

  return (
    <>
      {flairList}
    </>
  )
}

export default Flair;