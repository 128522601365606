import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useFirebase } from 'services/Firebase';
import { useUser } from 'services/User';
import { useMessaging } from 'services/Messaging';
import { Button } from 'react-bootstrap';
import { RiMailLine, RiMailOpenLine } from 'react-icons/ri';
import { DateTime } from 'luxon';

function MessageTable () {
  const { user } = useUser();
  const { messages, unreadMessages, setMessages } = useMessaging();
  const db = useFirebase().db;

  const uid = user && user.uid;
  const filtered = useRef(true);
  const [filteredMessages, setFilteredMessages] = useState(unreadMessages);
 
  const filterMessages = useCallback(() => {
    if (filtered.current) {
      setFilteredMessages(unreadMessages);
    } else {
      setFilteredMessages(messages)
    }
  }, [messages, unreadMessages]);

  useEffect(() => {
    filterMessages();
  }, [filterMessages]);

  
  const toggleMessage = async (id) => {
    messages.forEach((message) => {
      if (message.id === id) {
        message.read = !message.read;
      }
    });
    if (Array.isArray(messages) && messages.length) {
      db.collection('users').doc(uid).collection('public').doc('messages').update({messages: messages});
    }
    setMessages(messages);
  }
  
  const handleFilterChange = () => {
    filtered.current = !filtered.current;
    filterMessages();
  }

  return (
    <div className="table-responsive border border-dark">
      <table className="table table-sm table-striped mb-0" >
        <thead>
          <tr><th colSpan="4"><h4>Messages</h4></th></tr>
          <tr>
            <th>Received</th>
            <th>Title</th>
            <th>Message</th>
            <th>
              <Button className="text-white" variant="link" onClick={handleFilterChange}>
                Show {filtered.current?'All':'Unread'}
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(filteredMessages) && filteredMessages.length?
            filteredMessages.map((message) => {
              const dt = DateTime.fromJSDate(message.receivedDate.toDate());
              const received = dt.toLocaleString(DateTime.DATE_SHORT);

              return (
                <tr key={message.id}>
                  <td>{received}</td>
                  <td>{message.title}</td>
                  <td>{message.message}</td>
                  <td>
                    <Button className="icon" variant="secondary" onClick={() => toggleMessage(message.id)}>{message.read?<><RiMailLine /> Mark Unread</>:<><RiMailOpenLine /> Mark As Read</>}</Button>
                  </td>
                </tr>
              )
            })
            :
            <tr><td colSpan="4">No Results</td></tr>
          }
        </tbody>
      </table></div>
  )
}

export default MessageTable;