import React, { useContext } from 'react';
import app from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    
    this.app = app;
    this.analytics = app.analytics();
    this.db = app.firestore();
    this.auth = app.auth;
    this.storage = app.storage();
    this.functions = app.functions();
    this.logEvent = ({type, ...props}) => {
      this.analytics.logEvent(type, {...props});
    };
    this.logError = ({error, ...props}) => {
      console.error('here', error)
      try {
        this.analytics.logEvent('exception', {...props});
      } catch (err) { console.error(err)}
    }
  }
}
export default Firebase;
export const firebase = new Firebase();

export const FirebaseContext = React.createContext(null);
export const useFirebase = () => {
  return useContext(FirebaseContext);
};
