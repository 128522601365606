import React, { useState, useEffect } from 'react';
import { Row, Col, Nav, Navbar } from 'react-bootstrap';
import { RiFilterLine } from 'react-icons/ri';
import SkillFilters from 'components/skills/SkillFilters';
import SkillSort from 'components/skills/SkillSort';
import SkillList from 'components/skills/SkillList';

let sortStatus = {
  currentSort: 'Name',
  sortDirection: 1, //1: ascending, -1: descending
  sortKeys: {
    Name: (a, b) => {
      if (a.name < b.name) {
        return (-1*sortStatus.sortDirection);
      } 
      if (a.name > b.name) {
        return 1*sortStatus.sortDirection;
      }
      return 0;
    },
    Status:  (a, b) => { 
      return (a.completed - b.completed)*sortStatus.sortDirection;
    },
    Competitors: (a, b) => (a.attempts.length-b.attempts.length)*sortStatus.sortDirection
  }
};

function SkillConsole({skills}) {
  const [filteredItems, setFilteredItems] = useState(sortList(skills, 'Name')); 
  const filterResults = React.useCallback((filterList) => {
    const filteredList = sortList(
      skills.filter((item) => 
        filterList.every((filter) =>
          filter.checkResults(item)              
        )
      )
    );
    setFilteredItems(filteredList.slice());
  }, [skills]);

  useEffect(() => {
    setFilteredItems(skills);
  }, [skills]);

  function handleSort(key) {
    if (key === sortStatus.currentSort) {
      sortStatus.sortDirection *= -1;
    } else {
      sortStatus.sortDirection = 1;
    }
    sortStatus.currentSort = key;
    setFilteredItems(sortList(filteredItems, key));
  };
  function sortList(list, key) {
    if (!key) {
      key = sortStatus.currentSort;
    }
    let sortFn = sortStatus.sortKeys[key];
    const sorted = list.sort(sortFn);
    return sorted.slice();
  }; 
  
  return (
    <div>
      <Row>
        <Col xs="8" className="pl-0" lg={{span:3, order:2}} >
          <Navbar className="pt-0" expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav"><RiFilterLine/></Navbar.Toggle>
            <Navbar.Collapse>
                <Nav className="flex-grow mr-auto text-left"  style={{"flexDirection": 'column', "flex": '1 0 auto'}}>
                  <SkillFilters skills={skills} onChange={filterResults} /> 
                </Nav>
              </Navbar.Collapse>
          </Navbar>
        </Col>
        <Col xs="4" lg={{span:12, order:1}} className="text-right align-self-end">
          <SkillSort onSort={handleSort} status={sortStatus} />
        </Col> 
        <Col xs="12" lg={{span:9, order:3}} >
          <SkillList items={filteredItems} />
        </Col>                            
      </Row>
    </div>
  );
}

export default SkillConsole;