import React from 'react';
import { Image } from 'react-bootstrap';
import { FaUserNinja } from 'react-icons/fa';
import "./Avatar.css";


function Avatar({profile, src, ...props}) {
  if (!src) {
    if (profile && profile.photoURL) {
      const cacheBuster = profile.photoURL.includes('?')?`&cb=${profile.photoCache}`:`?cb=${profile.photoCache}`;
      src = `${profile.photoURL}${cacheBuster}`
    }
  }

  let flair = '';
  let champ = '';
  if (profile && profile.admin) {
    flair = '/adminLogo.png';
  }
  if (profile && profile.champ) {
    champ = `/${profile.champ}.svg`;
  }
  const handleError = (e) => {
    e.target.onError = null;
    e.target.src = '/user-ninja-solid.svg'
  }
  return (
    <div className="avatar_flair_item">
    {
      src?
      <img onError={handleError} {...props} src={src} className={`avatar`} />
      :
      <FaUserNinja {...props} className={`avatar`}/>
    }
    {
      flair &&
      <div className="avatar_flair_badge">
        <Image src={flair} />
      </div>
    }
    {
      champ && 
      <div className="avatar_champ_badge">
        <Image src={champ} />
      </div>
    }
    </div>
  )
}

export default Avatar;