import React, { useState, useEffect } from 'react';
import Avatar from 'components/users/Avatar';
import SortableTable from 'components/SortableTable';
import { useHistory } from 'react-router-dom';
import { useFirebase } from 'services/Firebase';
import './LeaderboardTable.css';
import { FaVideo, FaVideoSlash } from 'react-icons/fa';
import SkillStatus from './SkillStatus';
import { Badge } from 'react-bootstrap';

function SkillLeaderboardTable({athletes, skills, skillId, results, period, resultsFilters=[], prizes}) {
  const history = useHistory();
  const db = useFirebase().db; 
  const skill = skills[skillId] || {};
  results = results || [];
  results = results.filter(user => resultsFilters.every(filter => filter(user)));
  
  const formatPrize = (amount) => {
    if (typeof amount === 'undefined') return null;
    return '$'+amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  const prizeRow = (row) => {
    return (
      <>
        <Badge variant="light">{formatPrize(row.original.amount)}</Badge>
      </>
    )
  }

  const data = React.useMemo(() => {
    if (Array.isArray(results)) {
      results.sort((a, b) => b.reps - a.reps);
      results.forEach((result, i) => {
        let rank = i+1;
        if (i > 0) {
          const lastResult = results[i-1];
          if (lastResult.reps === result.reps) {
            rank = lastResult.rank;
          }
        }
        result.rank = rank;
      });
    }
    return results;
  }, [results]);
  const columns = React.useMemo(() => [
    {
      Header: <>
                <h4>{skill.name}<br/><small><SkillStatus skill={skill} period={period}></SkillStatus></small></h4>
                <small><em>*prize amounts will lower as new skills are added</em></small>
              </>,
      id: {skillId},
      columns: [
        {
          Header: '',
          accessor: 'reviewed',
          Cell: ({value}) => {
            return value ? <div className="text-info"><FaVideo /></div> : <FaVideoSlash />
          }
        },
        {
          Header: 'Reps',
          accessor: 'reps'
        },
        {
          Header: 'Image',
          accessor: 'uid',
          id: 'image',
          Cell: ({value}) => {
            console.log(athletes);
            console.log(value)
            const athlete = athletes[value];
            if (!athlete) return null;
            return <Avatar profile={athlete} />
          }
        },
        {
          Header: 'Name',
          accessor: 'uid',
          id: 'name',
          Cell: ({value}) => {
            const athlete = athletes[value];
            if (!athlete) return null;
            return athlete.displayName
          }
        },
        {
          Header: 'Prizes',
          accessor: 'uid',
          id: 'prizes',
          Cell: ({row, value}) => {
            const athlete = athletes[value];
            let ret = '';
            if (athlete && athlete.admin) {
              ret = <Badge variant="success">ADMIN</Badge>
            } else if (athlete && !athlete.subscribed) {
              ret = <Badge variant="warning">FREE</Badge>
        
            } else {
              ret = prizeRow(row)
            }
            
            return (
              <span className="float-right">
                {ret}
              </span>
            );
          }
        }
      ]
    }
  ], [athletes, skillId, period])

  const handleClick = (row) => {
    history.push(`/Home/${row.original.uid}`, {period: period})
  }
  
  if (!(data && skill)) return <h4>No Results</h4>

  return (
    <>
      <SortableTable columns={columns} data={data} onRowSelect={handleClick} height="500px"/>
    </>
  )
}

export default SkillLeaderboardTable;