import React from 'react';
import { getLocalDT } from 'services/Utilities';

function FormattedDate({date, format='YYYY/MM/dd'}) {
  if (!date) return null;

  date = date.toDate();
  const dt = getLocalDT(date);
  
  return (
    <>
      {dt.toFormat(format)}
    </>
  )
}

export default FormattedDate;