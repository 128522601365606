import React, { useState, useEffect, useRef } from 'react';
import SkillConsole from 'components/skills/SkillConsole';
import SkillDetails from 'components/skills/SkillDetails';
import SkillAttemptForm from 'components/skills/SkillAttemptForm';
import { useParams } from 'react-router-dom';
import { getSeasonId, Season, getWeekNumber } from 'services/Utilities';
import { useFirebase } from 'services/Firebase';
import { useUser } from 'services/User';
import { trackPromise } from 'react-promise-tracker';
import Notifications from 'components/notification/Notification';

function Skills() {
  const {skillId} = useParams();
  const [skills, setSkills] = useState([]);
  const [skill, setSkill] = useState({});

  const isCancelled = useRef(false);

  const db = useFirebase().db;
  const {user} = useUser();
  const seasonId = getSeasonId();
  const weekNumber = getWeekNumber();
  console.log(weekNumber)
  useEffect(() => {
    return () => {isCancelled.current = true;}
  },[]);

  useEffect(() => {
    let skillList = [];
    const getSkills = async () => {
      const results = await db.collection('seasons').doc(seasonId).collection('season_skills').where('weeks', 'array-contains', weekNumber)
      .get()
      .catch((err) => console.error(err));
      results.forEach((doc) => {
        let data = doc.data();
        data.attempts = data.attempts || [];
        skillList.push(Object.assign({id: doc.id, completed: data.attempts.includes(user.uid)}, data ))
      });
      
      if(!isCancelled.current) {
        setSkills(skillList);
      }
    }
    trackPromise(getSkills());
  }, [user, seasonId, db]);

  useEffect(() => {
    if(!isCancelled.current && skillId && skills) {
      setSkill(skills.find(x => x.id === skillId));
    }
  }, [skills, skillId]);

  const skillDetail = (
    <div className="container">
      <SkillDetails skill={skill} />
      <br/>
      <SkillAttemptForm skill={skill} />
    </div>
  )
  const skillList = (
    <div>
      <Notifications types={['rules']} />
      <h2>
        Skills
      </h2>
      <h5 className="text-secondary">
        <Season seasonId={seasonId} /> Season
      </h5>
      <SkillConsole skills={skills}/>
    </div>
  )

  return (
    <>
      {
        skillId?
        skillDetail
        :
        skillList
      }
    </>
  );
}

export default Skills;