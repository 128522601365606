import React from 'react';
import { useTable, useSortBy } from 'react-table';
import ScrollBar from 'react-scrollbars-custom';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';

function SortableTable({columns, data, onRowSelect, height}) {
  let { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({columns, data}, useSortBy)

  return (
    <>
      <div className="table-responsive border border-dark">
        <table className="table table-sm table-striped mb-0" {...getTableBodyProps()}>
          <thead className="pt-1 pb-1">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                      {column.isSorted? 
                        <span className="pl-1">
                          {column.isSortedDesc? 
                            <FaArrowCircleDown />
                            :
                            <FaArrowCircleUp />
                          }
                        </span>
                        : ''
                      }
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                const clickProps = 
                  onRowSelect?
                  {
                    className: 'cursor_pointer',
                    onClick: () => onRowSelect(row)
                  }
                  :{};
                return (
                  <tr {...clickProps} {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })
            }
          </tbody>
          {data.length === 0 && <caption className="text-center border">No Results</caption>}
        </table>
      </div>
    </>
  )
}

export default SortableTable;