import React from 'react';
import { Field, ErrorMessage } from 'formik';
import Tooltip from 'components/Tooltip';

function InputField(props) {
  return (
    <>
      {
        props && props.type === 'hidden' ?
        <div className="error_message col-12">
          <ErrorMessage name={props.name}  />
        </div>
        :
        <>
          <label htmlFor={props.name} className="col-sm-3 col-form-label text-left text-sm-right">
            {props.label}: {props.tooltip&&<Tooltip>{props.tooltip}</Tooltip>}
          </label>
          <div className="col-sm-9">
            <Field {...props}>
              {props.options && props.options.map((option) => 
                <option key={option} value={option}>{option}</option>
              )}
            </Field>
            <div className="error_message">
              <ErrorMessage name={props.name}  />
            </div>
          </div>
        </>
      }
    </>
  )
}

export default InputField;