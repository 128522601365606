import React, {useEffect, useState} from 'react';
import { useFirebase } from 'services/Firebase';
import SortableTable from 'components/SortableTable';
import { trackPromise } from 'react-promise-tracker';
import { DateTime } from 'luxon';


function Users () {
  const db = useFirebase().db;
  const [users, setUsers] = useState();

  useEffect(() => {
    const getData = async () => {
      const userSnap = await db.collectionGroup('athletes').get();
      const promises = userSnap.docs.map(doc => {
        return doc.ref.collection('private').get()
        .then(privateSnap => {
          const user = doc.data();
          privateSnap.forEach(privateDoc => {
            const data = privateDoc.data();
            if (privateDoc.id === 'billing') {
              let startDate = '';
              if (data.subscriptionStartDate && typeof data.subscriptionStartDate.toDate == 'function') {
                startDate = DateTime.fromJSDate(data.subscriptionStartDate.toDate()).toFormat('yyyy/LL/dd');
              }
              let endDate = '';
              if (data.subscriptionEndDate && typeof data.subscriptionEndDate.toDate == 'function') {
                endDate = DateTime.fromJSDate(data.subscriptionEndDate.toDate()).toFormat('yyyy/LL/dd');
              }
              
              data.subscriptionStartDate = startDate;
              data.subscriptionEndDate = endDate;
            }
            if (privateDoc.id === 'winnings') {
              let winnings = 0;
              let redeemed = 0;
              let pending = 0;
              if (Array.isArray(data.prizes)) {
                winnings = data.prizes.reduce((a, b) => {
                  return {amount: a.amount + b.amount};
                }, {amount: 0}).amount;
              }
              if (Array.isArray(data.redemptions)) {
                redeemed = data.redemptions.reduce((a, b) => {
                  if (b.redeemed) {
                    return {amount: a.amount + b.amount};
                  } else {
                    return a;
                  }
                }, {amount: 0}).amount;
                pending = data.redemptions.reduce((a, b) => {
                  if (!b.redeemed) {
                    return {amount: a.amount + b.amount};
                  } else {
                    return a;
                  }
                }, {amount: 0}).amount;
              }
              data.total = winnings;
              data.redeemed = redeemed;
              data.pending = pending;
            }
            user[privateDoc.id] = data;
          })
          return user;
        })
      });
      return Promise.all(promises)
      .then(userList => {
        setUsers(userList)
      })
    }

    trackPromise(getData());
  }, []);

  const data = React.useMemo(() => {
    if (Array.isArray(users)) {
      return users;
    } else {
      return [];
    }
  }, [users]);
  const columns = React.useMemo(() => [
    {
      Header: <h4>User</h4>,
      id: 'users',
      columns: [
        {
          Header: 'ID',
          accessor: 'uid'
        },
        {
          Header: 'Display Name',
          accessor: 'displayName'
        }
      ]
    },
    {
      Header: <h4>Billing</h4>,
      id: 'billing',
      columns: [
        {
          Header: 'Subscription ID',
          accessor: 'billing.subscriptionID'
        },
        {
          Header: 'Start Date',
          accessor: 'billing.subscriptionStartDate'
        },
        {
          Header: 'End Date',
          accessor: 'billing.subscriptionEndDate'
        },
        {
          Header: 'Amount',
          accessor: 'billing.subscriptionAmount'
        }
      ]
    },
    {
      Header: <h4>Winnings</h4>,
      id: 'winnings',
      columns: [
        {
          Header: 'Total',
          accessor: 'winnings.total'
        },
        {
          Header: 'Pending',
          accessor: 'winnings.pending'
        },
        {
          Header: 'Redeemed',
          accessor: 'winnings.redeemed'
        }
      ]
    }
  ], [])

  return (
    <>
      <SortableTable columns={columns} data={data} /> 
    </>
  )
}

export default Users;