import React from 'react';
import { Navbar, Nav, NavItem, Dropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useUser, USER_STATUS } from 'services/User';
import { RiHomeSmileLine, RiTimerLine, RiTrophyLine, RiLoginBoxLine, RiLogoutBoxLine, RiEditBoxLine, RiAddCircleLine } from 'react-icons/ri';
import { FaDoorOpen, FaRegUserCircle, FaRegQuestionCircle, FaTv, FaVideo, FaUser, FaUserNinja, FaBlog } from 'react-icons/fa';
import HeaderAvatar from 'components/users/HeaderAvatar';
import './Header.css';


const sideMenu = [
  {
    icon: FaDoorOpen,
    title: 'Welcome',
    to: '/Welcome',
    minLevel: 0
  },
  {
    icon: RiHomeSmileLine,
    title: 'Home',
    to: '/Home',
    minLevel: 1
  },
  {
    title: 'Skills',
    items: [
      {
        icon: FaUserNinja,
        title: 'Athletes',
        to: '/Athletes',
        minLevel: 1.5
      },
      {
        icon: RiTrophyLine,
        title: 'Leaderboard',
        to: '/Leaderboard',
        minLevel: 1.5
      },
      {
        icon: RiTimerLine,
        title: 'View/Attempt',
        to: '/Skills',
        minLevel: 0
      },
      {
        icon: FaTv,
        title: 'Skill TV',
        to: '/TV',
        minLevel: 1.5
      }
    ]
  },
  {
    title: 'Admin',
    items: [
      {
        icon: RiAddCircleLine,
        title: 'Add Skill',
        to: '/Admin/Skill/Edit',
        adminLevel: 1
      },
      {
        icon: RiEditBoxLine,
        title: 'Edit Skills',
        to: '/Admin/Skill',
        adminLevel: 1
      },
      {
        icon: FaVideo,
        title: 'Video Review',
        to: '/Admin/Review',
        adminLevel: 1
      },
      {
        icon: FaUser,
        title: 'Users',
        to: '/Admin/Users',
        adminLevel: 1
      },
      {
        icon: FaUser,
        title: 'View Attempts',
        to: '/Admin/ViewAttempts',
        adminLevel: 1
      }   
    ]
  }
];

function Header() {
  const {user} = useUser();
  const isSignedIn = user.status >= USER_STATUS.SIGNED_IN;

  const MenuItem = (props) => {
    //Check if this is a dropdown
    if (Array.isArray(props.items) && props.items.length) {
      const children = [];
      props.items.forEach((item) => {
        const child = MenuItem(item);
        if (child) {
          children.push(child);
        }
      })

      if (children.length) {
        return ( 
          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link}>
              {props.title}
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-dark">
              {
                props.items.map((item, i) => <MenuItem key={i} {...item} />)
              }
            </Dropdown.Menu>
          </Dropdown>
        )
      } else {
        return null;
      }
    } 

    let userLevel = user.status || 0;
    let adminLevel = user.adminStatus || 0;
    let show = false;
    
    if(props.hasOwnProperty('minLevel')) {
      show = props.minLevel <= userLevel;
    }
    if(props.hasOwnProperty('adminLevel')) {
      show = props.adminLevel <= adminLevel;
    }

    if (show) {
      const Icon = props.icon;
      return (
        <LinkContainer key={props.title} to={props.to}>
          <Nav.Link> 
            <Icon className="d-lg-none" /> 
            &nbsp;{props.title}
          </Nav.Link>
        </LinkContainer>
      )
    } else {
      return null;
    }
  }

  return (
    <Navbar bg="dark" expand="lg" variant="dark" collapseOnSelect>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <LinkContainer to="/">
        <Navbar.Brand className="pl-5 pl-lg-0">
          <img src="/logo.svg" height="45" className="d-inline-block align-top" alt="Ninja Skills League Logo" />
        </Navbar.Brand>  
      </LinkContainer>
      <div className="d-flex flex-row order-2 order-lg-3">
        <Nav className="flex-row">
          <NavItem className="align-middle pr-1">
            <LinkContainer to="/Help">
              <Nav.Link>
               <FaRegQuestionCircle className="header_icon" />
              </Nav.Link>
            </LinkContainer>
          </NavItem>
          <NavItem className="align-middle">
            {isSignedIn?
              <LinkContainer to="/User">
                <Nav.Link>
                  <HeaderAvatar profile={user.publicProfile} />
                </Nav.Link>
              </LinkContainer>
            :
              <LinkContainer to="/Login">
                <Nav.Link>
                  <FaRegUserCircle className="header_icon" />
                </Nav.Link>
              </LinkContainer>
            }
          </NavItem>
        </Nav>        
      </div>   
      
      <Navbar.Collapse id="basic-navbar-nav" className="order-3 order-lg-2">
        <Nav className="mr-auto text-left">
          {
            sideMenu.map((item, i) => <MenuItem key={i} {...item} />)
          }
          <a href="https://blog.skillsleague.ninja" className="nav-link">
            <FaBlog className="d-lg-none"/> Blog
          </a>
          { isSignedIn?
            <LinkContainer to="/Logout">
              <Nav.Link> 
                <RiLogoutBoxLine className="d-lg-none" /> 
                Log Out
              </Nav.Link>
            </LinkContainer>
            :
            <LinkContainer to="/Login">
              <Nav.Link> 
                <RiLoginBoxLine className="d-lg-none" /> 
                Log In
              </Nav.Link>
            </LinkContainer>
          }
         
        </Nav>
      </Navbar.Collapse>
      
    </Navbar>
  );
};
    
export default Header;