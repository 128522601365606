import React, {useState, useEffect, useCallback} from 'react';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useFirebase } from 'services/Firebase';
import {Row, Col} from 'react-bootstrap';
import Loader from 'components/Loader';
import LeaderboardTable from './LeaderboardTable';
import SkillLeaderboardTable from './SkillLeaderboardTable';
import LeagueLeaderboard from './LeagueLeaderboard';
import MonstroLeaderboard from './MonstroLeaderboard';



function Leaderboards({period, visibleLocations, resultsFilters, onLocationChange}) {
  const db = useFirebase().db; 
  
  const [leaderboards, setLeaderboards] = useState();
  const [skillboards, setSkillboards] = useState();
  const [athletes, setAthletes] = useState();
  const [skills, setSkills] = useState();
  const [prizes, setPrizes] = useState();
  const [locations, setLocations] = useState([]);
  
  const area = 'leaderboards';
  const {promiseInProgress} = usePromiseTracker({area: area});

  useEffect(() => {
    const getData = async () => {
      const athleteSnap = await db.collectionGroup('athletes').get().catch(console.error)
      let athletes = {};
      athleteSnap.forEach(doc => {
        athletes[doc.id] = doc.data();
      });
      setAthletes(athletes);

      const skillsSnap = await db.collection('seasons').doc(period.seasonId).collection('season_skills').get().catch(console.error)
      let skills = {};
      skillsSnap.forEach(doc => {
        skills[doc.id] = doc.data();
      });
      setSkills(skills);

      const prizesDoc = await db.collection('seasons').doc(period.seasonId).get().catch(console.error)
      if (prizesDoc.exists) {
        const data = prizesDoc.data();
        setPrizes(data);
      }
    }
    trackPromise(getData(), area)
  }, [db, period]);

  useEffect(() => {
    console.log('getting data')
    const getData = async () => {
      const boardSnap = await db.collection('seasons').doc(period.seasonId)
      .collection('periods').doc(period.period)
      .collection('leaderboards')
      .get();

      const skillboards = {};
      const leaderboardList = {};
      const locationList = [];
      let hasSkills = false;
      boardSnap.forEach((board) => {
        console.log(board)
        const data = board.data();
        if (board.id === 'Overall' || board.id === 'Gym' || board.id === 'Home' || board.id === 'Monstro') {
          leaderboardList[board.id] = data;
          locationList.push(board.id);
        } else if (data.skillId) {
          hasSkills = true;
          skillboards[data.skillId] = data;
        }
      })
      //filter location list
      if (hasSkills) {
        locationList.push('Skills')
      }
      console.log(locationList)
      onLocationChange(locationList);
      setLocations(locationList);
      setSkillboards(skillboards);
      setLeaderboards(leaderboardList);
    }

    if (period) {
      trackPromise(getData(), area)
    }
  }, [db, period]);
  
  if (!(leaderboards && skillboards && athletes && skills && 
    Array.isArray(visibleLocations) && visibleLocations.length)) return <h4>No Results</h4>

  return (
    <>
      {promiseInProgress ?
        <Loader area={area} />
        :
        <>
          <Row>
            {
              locations.map((location) => {
                if (location === 'Skills') return null; //Ignore skill boards here
                if (visibleLocations.includes(location)) {
                  const board = leaderboards[location] || {results: []};
                  return (
                    <Col key={`${location}_${period}`} xs={12} className="text-center mb-2 leaderboard_table">
                      <LeagueLeaderboard athletes={athletes} location={location} final={board.final} results={board.results} period={period} resultsFilters={resultsFilters} prizes={prizes}/>
                    </Col>
                  )
                }
              })
            }
          </Row>
          {/*<Row>
            {
              ['Monstro'].map((location) => {
                if (visibleLocations.includes(location)) {
                  const board = leaderboards[location] || {results: []};
                  return (
                    <Col key={`${location}_${period}`} xs={12} md={6} className="text-center mb-2 leaderboard_table">
                      <MonstroLeaderboard athletes={athletes} location={location} final={board.final} results={board.results} period={period} resultsFilters={resultsFilters} />
                    </Col>
                  )
                }
              })
            }
          </Row>*/}
          <Row>
            {
              visibleLocations.includes('Skills') && skillboards &&
              Object.entries(skillboards).map((skill) => {
                const skillId = skill[0];
                const board = skill[1];
                return (
                  <Col key={skillId} xs={12} md={6} lg={4} className="text-center mt-2 mb-2 leaderboard_table">
                    <SkillLeaderboardTable athletes={athletes} skills={skills} skillId={skillId} results={board.results} period={period} resultsFilters={resultsFilters} prizes={prizes} />
                  </Col>
                )
              })
            }
          </Row>
        </>
      }
    </>
  )
}

export default Leaderboards;