import React from 'react';
import FirebaseAuth from 'react-firebaseui/FirebaseAuth';
import * as firebaseui from 'firebaseui';
import { useFirebase } from 'services/Firebase';

function LoginContainer() {
  const firebase = useFirebase();
  const uiConfig = {
    signInSuccessUrl: '/Home',
    signInFlow: (('standalone' in window.navigator) && window.navigator.standalone)
    ? "redirect" : "popup",
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        clientId: process.env.REACT_APP_FIREBASE_AUTH_CLIENT_ID
      },
      firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    // tosUrl and privacyPolicyUrl accept either url string or a callback
    // function.
    // Terms of service url/callback.
    tosUrl: '/TOS',
    // Privacy policy url/callback.
    privacyPolicyUrl: '/Privacy',
    credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
  };
  
  return (
    <div>
      <FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    </div>
     
  );
}

export default LoginContainer;