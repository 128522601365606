import React, {useState, useCallback} from 'react';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { getAge } from 'services/Utilities';


function AgeGroupToggle({onChange}) {
  const ageGroups = ['All', '0-10', '11-14', '15-39', '40+'];
  const [selectedAgeGroup, setSelectedAgeGroup] = useState('All');

  const handleAgeGroup = (ageGroup) => {
    setSelectedAgeGroup(ageGroup);   
    return onChange(isInAgeGroup(ageGroup));
  }
  const isInAgeGroup = (ageGroup) => {
    return (user) => {
      const age = getAge(user.birthday);
      if (ageGroup === 'All') {
        return true;
      }
      if (ageGroup === '0-10' && age <= 10) {
        return true;
      }
      if (ageGroup === '11-14' && age >= 11 && age <= 14) {
        return true;
      }
      if (ageGroup === '15-39' && age >= 15 && age <=39) {
        return true;
      }
      if (ageGroup === '40+' && age >= 40) {
        return true;
      }
      return false;
    }
  }

  return (
    <>
      <div>Age Groups</div>
      <ToggleButtonGroup type="radio" name="ageGroups" value={selectedAgeGroup}>
        {ageGroups.map((ageGroup) => (
          <ToggleButton onChange={() => handleAgeGroup(ageGroup)} key={ageGroup} value={ageGroup}>{ageGroup}</ToggleButton>
        ))}
      </ToggleButtonGroup>
    </>
  )
}

export default AgeGroupToggle;