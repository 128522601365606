import React from 'react';
import { Jumbotron, Image } from 'react-bootstrap';

function Maintenance() {

  return (
    <Jumbotron className="text-center">
      <Image className="w-25" src="/logo.png"></Image>
      <h3>Down for Maintenance</h3>
      <p>We'll be back soon.</p>
    </Jumbotron>
    
  )
}

export default Maintenance;