import React from 'react';
import { FaHome } from 'react-icons/fa';

function LocationIcon({location, size="50px"}) {

  if (!location) return null;

  return (
    <>
      {(location==='Gym') && <><img alt="Gym" src="/WarpedWallIcon.svg" style={{height: size}} /><div className="small"  style={{lineHeight: '2'}}>GYM</div></>}
      {(location==='Home') && <><FaHome alt="Home" size={size} /><div className="small"  style={{lineHeight: '.1'}}>HOME</div></>}
    </>
  )
}

export default LocationIcon;