import React, { useState, useEffect } from 'react';
import { useUser } from 'services/User';
import SkillFilter from 'components/skills/SkillFilter';

function SkillFilters({skills, onChange}) {
  const {user} = useUser();
  
  const status = ['Completed', 'Not Completed'];
  
  const [locations, setLocations] = useState([])
  const [locationFilter, setLocationFilter] = useState([]);
  const [equipmentFilter, setEquipmentFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState(status);
  const [equipment, setEquipment] = useState([]);

  useEffect(() => {
    let allEquipment = [];
    skills.forEach((skill) => allEquipment.push(...skill.equipment));
    const equipmentList = allEquipment.filter((value, index, self) => value && self.indexOf(value) === index);
    setEquipment(equipmentList)
    setEquipmentFilter(equipmentList.slice());

    let allLocations = [];
    skills.forEach((skill) => allLocations.push(skill.location));
    const locationList = allLocations.filter((value, index, self) => value && self.indexOf(value) === index);
    setLocations(locationList)
    setLocationFilter(locationList.slice());
  }, [skills]);

  let filters = [
    {
      title: 'Location',
      list: locations,
      filteredList: locationFilter,
      setFilter: setLocationFilter,
      checkResults: function(skill) {
        return this.filteredList.includes(skill.location)
      }
    },
    {
      title: 'Status',
      list: status,
      filteredList: statusFilter,
      setFilter: setStatusFilter,
      checkResults: function(skill) {
        const completed = skill.completed && this.filteredList.includes('Completed');
        const notCompleted = !skill.completed && this.filteredList.includes('Not Completed');
        return completed || notCompleted
      }
    }
  ];

  if (equipment && equipment.length) {
    filters.push({
      title: 'Equipment',
      list: equipment,
      filteredList: equipmentFilter,
      setFilter: setEquipmentFilter,
      checkResults: function(skill) {
        if (!skill.equipment || skill.equipment.length === 0) {
          return true;
        }
        return skill.equipment.every(v => this.filteredList.includes(v))
      }
    })
  }
  
  function handleFilter(filter) {
    return (e) => {
      //Remove item from filter list
      updateFilterList(e.target.value, e.target.checked, filter);
      onChange(filters);
    };
  };
  
  const updateFilterList = (value, checked, filter) => {
    const list = filter.filteredList;
    if (checked) {
      list.push(value);
    } else {
      let index = list.indexOf(value)
      list.splice(index, 1);
    }
    filter.setFilter(list);
  };

  if (!skills) {
    return null;
  }
  return (
    <>
      {
        filters.map((filter) =>
          <SkillFilter onChange={handleFilter(filter)} key={filter.title} {...filter} />
        )
      }
    </>
  )
}

export default SkillFilters;