import React, { useState, useEffect } from 'react';
import Video from 'components/video/Video';
import { Row, Col, Button } from 'react-bootstrap';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';


function VideoPlayer ({attempts, autoPlay, play, onSpeedChange, onPause, onVideoChange, showSpeed, showStats=true}) {
  const [attemptIndex, setAttemptIndex] = useState(0);
  useEffect(() => {
    setAttemptIndex(0);
  }, [attempts])

  const handleVideoChange = (i) => {
    setAttemptIndex(i);
    if (onVideoChange) {
      onVideoChange(attempts[i]);
    }
  }

  const handleEnd = () => {
    if (autoPlay && attemptIndex !== attempts.length) {
      setAttemptIndex(attemptIndex+1);
    }
  }

  return (
    <>
    {attempts && attempts[attemptIndex] &&
      <>
        {showStats &&
          <>
            <Row xs={1} sm={2}>
              <Col>Skill: <strong>{attempts[attemptIndex].skillName}</strong></Col>
              <Col>Ninja: <strong>{attempts[attemptIndex].displayName}</strong></Col>          
            </Row>
            <Row xs={1} sm={2}>
              <Col>Reps: <strong>{attempts[attemptIndex].reps}</strong></Col>
              <Col>Location: <strong>{attempts[attemptIndex].location}</strong></Col>
            </Row>
          </>
        }
        <Video src={attempts[attemptIndex].url} onEnded={handleEnd} autoPlay={autoPlay} play={play} onSpeedChange={onSpeedChange} onPause={onPause} showSpeed={showSpeed} />
        <Row >
          <Col className="text-left">
            <Button className="icon" size="lg" variant={attemptIndex===0?'secondary':'primary'} disabled={attemptIndex===0} onClick={() => handleVideoChange(attemptIndex-1)}>
              <FaChevronLeft /> Prev
            </Button>
          </Col>
          <Col className="text-right">
            <Button className="icon" size="lg" variant={attemptIndex===attempts.length-1?'secondary':'primary'} disabled={attemptIndex===attempts.length-1} onClick={() => handleVideoChange(attemptIndex+1)}>
              Next <FaChevronRight  /></Button>
          </Col>
        </Row>
      </>
    }
    </>
  )
}

export default VideoPlayer;