import React, {useEffect, useState} from 'react';
import { DateTime } from 'luxon';
import { isDate } from 'moment';
const timeZone = 'America/Detroit';


export function PlacementText(props){
  if (props.place) {
    let place = String(props.place);
    switch(place.slice(-1)){
      case '1': return `${place}st`;
      case '2': return `${place}nd`;
      case '3': return `${place}rd`;
      case '4':
      case '5':
      case '6':
      case 7:
      case 8:
      case 9: return `${place}th`;
      default: return place;
    }
  }
};

export function uuid() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => {
    let temp = c ^ crypto.getRandomValues(new Uint8Array(1))[0];
    temp = temp & 15;
    temp = temp >> c / 4;
    return temp.toString(16)
  });
}

export function getLocalDT (jsDT) {
  let dt;
  if (jsDT && isDate(jsDT)) {
    dt = DateTime.fromJSDate(jsDT, {zone: timeZone})
  } else {
    dt = DateTime.local().setZone(timeZone);
  }
  return dt;
}

export function getSeasonId() {
  const dt = getLocalDT();
  
  // Return year and week
  return dt.toFormat('yyyyMM');
}

export function getWeekNumber() {
  const dt = getLocalDT();
  const day = dt.toFormat('d');
  const weekday = dt.weekday;
  return (Math.ceil((day - weekday) / 7) + 1).toString();
}

export function getDatesFromWeekNumber(weekNumber, seasonId) {
  seasonId = seasonId || getSeasonId();
  if (!(seasonId && weekNumber)) return null;

  const returnFormat = DateTime.DATE_FULL;
  const year = parseInt(seasonId.substring(0, 4), 10);
  const month = parseInt(seasonId.substring(4, 6), 10);
  const startOfMonth =  DateTime.local(year, month, 1);
  let startDate, endDate;
  
  
  //Get the weekday for the first of the month
  const startWeekday = startOfMonth.weekday;
  const daysPerWeek = 7;
  const startOfWeek = daysPerWeek * weekNumber - (5 + startWeekday);
  if (weekNumber === '1') {
    startDate = startOfMonth;
    endDate = startDate.plus({days: (7-startWeekday)});
  } else {
    startDate = DateTime.local(year, month, startOfWeek);
    endDate = startDate.plus({days: 6});
  
    //Check if endDate is a new month
    if (endDate.month !== month) {
      endDate = startOfMonth.plus({months: 1}).minus({days: 1});
    }
  }
  
  return [startDate.toLocaleString(returnFormat), endDate.toLocaleString(returnFormat)];
}

export function getStartDateFromWeekNumber(seasonId, weekNumber) {
  if (!(seasonId && weekNumber)) return null;

  const returnFormat = DateTime.DATE_FULL;
  const year = seasonId.substring(0, 4);
  const month = seasonId.substring(4, 6)
  const startOfMonth =  DateTime.local(year, month, 1);
  if (weekNumber === '1') return startOfMonth.toLocaleString(returnFormat);
  
  //Get the weekday for the first of the month
  const startWeekday = startOfMonth.weekday;
  const daysPerWeek = 7;
  const startOfWeek = daysPerWeek * weekNumber - (5 + startWeekday);
  return DateTime.local(year, month, startOfWeek).toLocaleString(returnFormat)
}

export function getNext6Seasons() {
  const dt = getLocalDT();
  const seasons = [''];
  
  for (var i=0; i<6; i++) {
    seasons.push(dt.plus({months: i}).toFormat('yyyyMM'))
  }
  // Return year and week
  return seasons;
}

export const Season = ({seasonId}) => {
  seasonId = seasonId || '';
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
  ];
  return monthNames[seasonId.slice(4)-1] + ' ' + seasonId.slice(0, 4);
}

export function getAge(birthday) {
  if (!birthday) return "?"
  const yearInMs = 3.15576e+10
  return Math.floor((new Date() - new Date(birthday).getTime()) / yearInMs)
}

export function getParentAccountId(uid) {
  if (!uid) return null;

  const idx = uid.indexOf('_');
  if (idx === -1) {
    return uid; //Parent account
  }

  return uid.substring(0, idx);
}

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}