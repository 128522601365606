import React from 'react';
import { Accordion } from 'react-bootstrap';
import SkillItem from './SkillItem';

function SkillList({items}) {
  return (
    <>
    {(Array.isArray(items) && items.length)?
      <Accordion defaultActiveKey="0">
        {items.map((skill) =>
          <SkillItem key={skill.id} skill={skill}>
          </SkillItem>  
        )}
      </Accordion>
     :
     <h4>No available skills</h4>
    }
    </>
  )
};

export default SkillList;