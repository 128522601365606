import React, {useCallback} from 'react';
import makeAsyncScriptLoader from 'react-async-script'; 

function PayPalButtons ({planId, onApprove}) {
  const PayPalButton = () => 
    <div id="paypal-button-container"></div>

  const initPayPal = useCallback(async () => {
    
    let paypal = window['paypal'];
    paypal.Buttons({ 
      style: { 
        shape: 'rect', 
        color: 'black', 
        layout: 'vertical', 
        label: 'subscribe', 
      }, 
      createSubscription: function(data, actions) { 
        return actions.subscription.create({ 
          'plan_id': planId
        }); 
      }, 
      onApprove: function(data, actions) { 
        onApprove(data.subscriptionID); 
      } 
    }).render('#paypal-button-container');
  }, [planId]);

  const AsyncButton = makeAsyncScriptLoader("https://www.paypal.com/sdk/js?client-id=AcJFJQelkRreAm119RRL0xnhtQRp0SjrjTDPddOmaFVgFBu-6DZU8IsWQrIueiV1vblbyRJDgqFzRXAX&vault=true", {globalName: 'paypal'})(PayPalButton);
  
  return (
    <AsyncButton asyncScriptOnLoad={initPayPal}></AsyncButton>
  )
}

export default PayPalButtons;