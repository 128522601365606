import React, { useEffect, useState } from 'react';
import { useFirebase } from 'services/Firebase';
import { DateTime } from 'luxon';
import { trackPromise } from 'react-promise-tracker';
import SortableTable from 'components/SortableTable';
import Avatar from 'components/users/Avatar';
import { useHistory } from 'react-router-dom';

function Athletes () {
  const db = useFirebase().db;
  const [users, setUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const getData = async () => {
      const userSnap = await db.collectionGroup('athletes').where('subscribed', '==', true).get().catch(console.error);
      const userList = [];
      userSnap.forEach(async (userDoc) => {
        const user = userDoc.data();
        if (!user.displayName) return null;

        let lastAttemptDate = '';
        if (user.lastAttemptDate && typeof user.lastAttemptDate.toDate == 'function') {
          lastAttemptDate = DateTime.fromJSDate(user.lastAttemptDate.toDate()).toFormat('yyyy/LL/dd');
        }
        
        user.lastAttemptDate = lastAttemptDate || 'N/A';
        
        userList.push(user);
      });
      setUsers(userList.sort((a, b) => {
        if (a.displayName < b.displayName) {
          return -1;
        }
        if (a.displayName > b.displayName) {
          return 1;
        }
        return 0;
      }));
    }

    trackPromise(getData());
  }, []);

  const data = React.useMemo(() => {
    return users;
  }, [users]);
  const columns = React.useMemo(() => [
    {
      Header: 'Athlete',
      columns: [
        {
          Header: '',
          accessor: 'photoURL',
          Cell: ({value}) => <Avatar src={value} />
        },
        {
          Header: 'Display Name',
          accessor: 'displayName'
        },
        {
          Header: 'Last Attempt',
          accessor: 'lastAttemptDate'
        }
      ]
    },
    {
      Header: 'Division Stats',
      columns: [
        {
          Header: 'Seasons',
          accessor: 'flair.seasons'
        },
        {
          Header: 'Top 10',
          accessor: 'flair.top10Seasons'
        },
        {
          Header: '1st Place',
          accessor: 'flair.top1Seasons'
        }
      ]
    },
    {
      Header: 'Skill Stats',
      columns: [
        {
          Header: 'Skills',
          accessor: 'flair.skills'
        },
        {
          Header: 'Top 10',
          accessor: 'flair.top10Skills'
        },
        {
          Header: '1st Place',
          accessor: 'flair.top1Skills'
        }
      ]
    }
  ], [])

  const handleClick = (row) => {
    history.push(`/Home/${row.original.uid}`)
  }

  return (
    <>
      <h3>Active Members</h3>
      <SortableTable columns={columns} data={data} onRowSelect={handleClick} /> 
    </>
  )
}

export default Athletes;