import React, { useState, useMemo } from 'react';
import DynamicForm from 'components/forms/DynamicForm';
import { Image, Row, Col, Dropdown } from 'react-bootstrap';
import { useFirebase } from 'services/Firebase';
import { useUser } from 'services/User';
import { trackPromise } from 'react-promise-tracker';
import * as Yup from 'yup';

const gcOptions = [
  {
    name: 'Gripz Gym',
    website: 'https://gripzgym.com/',
    image: '/gripzgym.png',
    minAmount: 10
  },
  {
    name: 'Monstro Ninja Holds',
    website: 'https://www.monstro.ninja/',
    image: '/monstro.png',
    minAmount: 10
  },
  {
    name: 'Atomik Climbing Holds',
    website: 'https://www.atomikclimbingholds.com/',
    image: '/atomik.png',
    minAmount: 25
  },
  {
    name: 'Stronghold',
    website: 'https://strongholdninja.com/',
    image: '/stronghold.png',
    minAmount: 25
  }
]
function WinningsRedemption ({unused = 0, onRedeemed}) {
  const {user} = useUser();
  const [selectedOption, setSelectedOption] = useState();
  const [redemption, setRedemption] = useState({});
  const [error, setError] = useState();
  const {functions, logError} = useFirebase();

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    redemption.giftCard = option.name;
    setRedemption(redemption);
  }

  const GiftCardOption = ({option}) => {
    if (!option) return null;
    return (
      <>
        <Row col={1} col-md={2} className="text-md-left text-center">
          <Col xs={12} md={3}>
            <Image src={option.image} width={'100px'}/>
          </Col>
          <Col>
            <strong>{option.name}</strong> <span className="small">(min. amount: ${option.minAmount})</span><br/>
            <a href={option.website} target="_blank">{option.website}</a>
          </Col>
        </Row>
      </>
    )
  }

  const fields = useMemo(() => {
    const minAmount = selectedOption ? selectedOption.minAmount || 0 : 0;
    return [
      {
        name: 'giftCard',
        value: redemption.giftCard,
        as: 'input',
        type: 'hidden',
        validation: Yup.string()
        .required('*Select a Gift Card Type.')
      },
      {
        name: 'amount',
        label: 'Redemption Amount',
        value: redemption.amount,
        as: 'input',
        type: 'number',
        validation: Yup.number()
        .min(minAmount, `Minimum redemption amount is $${minAmount}`)
        .max(unused, `Maximum amount is $${unused}`)
        .required('*Required')
      }      
    ];
  }, [selectedOption]);

  const handleSubmit = (formValues) => {
    const values = {uid: user.uid, ...formValues};
    const updateData = async () => {
      const redeem = functions.httpsCallable('redeemWinnings');
      trackPromise(redeem({redemption: values})
      .then(() => {
        if (typeof onRedeemed === 'function') onRedeemed();
      })
      .catch((err) => {
        // Getting the Error details.
        var message = err.message;
        var details = err.details;
        setError('*Error redeeming. If error continues, please contact support.');
        logError({error: message, info: details, page_location: 'Redeem Winnings'});
      }));
    };

    updateData();
  };

  return (
    <>
      <h4>Redeem Your Winnings</h4>
      {error && <div className="text-danger">{error}</div>}
      <br/>
      <Dropdown>
        <Dropdown.Toggle variant="light" id="gcOptions">
          {
            selectedOption?
            <GiftCardOption option={selectedOption}/>
            :
            <span>Select Gift Card</span>
          }
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {gcOptions.map((item, i) => {
            const disabled = unused < item.minAmount;
            return (
              <Dropdown.Item className={disabled && 'bg-secondary text-light'} key={i} onClick={() => handleOptionChange(item)} disabled={disabled}>
                <GiftCardOption option={item}/>
              </Dropdown.Item>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
      <DynamicForm fields={fields} onSubmit={handleSubmit} submitLabel={'Redeem'} />
    </>
  )
}

export default WinningsRedemption;