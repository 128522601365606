import React, { useState, useEffect, useCallback } from 'react';
import VideoPlayer from 'components/video/VideoPlayer';
import Timer from 'components/video/Timer';
import { Dropdown, Row, Col, Button, FormCheck } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import VideoReviewForm from 'components/video/VideoReviewForm';
import AttemptVideoDropdown from 'components/video/AttemptVideoDropdown';
import { useFirebase } from 'services/Firebase';

function VideoReview() {
  const [currentAttempt, setCurrentAttempt] = useState({});
  const [playing, setPlaying] = useState(false);
  const [speed, setSpeed] = useState(1);
  const {db, functions, logError} = useFirebase();

  const [attempts, setAttempts] = useState();
  const [filteredAttempts, setFilteredAttempts] = useState();
  const [filtered, setFiltered] = useState(false);
  const [period, setPeriod] = useState();

  const getLeaderboards = async ({seasonId, period}) => {
    if (!(seasonId && period)) return null;

    const periodRef = db.collection('seasons').doc(seasonId)
      .collection('periods').doc(period);
    
    let boardList = {Gym: {}, Home: {}, Monstro: {}};
    let promises = ['Gym', 'Home', 'Monstro', 'Overall'].map((location) => {
      return periodRef.collection('leaderboards').doc(location).get()
      .then(doc => {
        if (doc.exists) {
          boardList[location] = doc.data();
        }
      })
    })
    return Promise.all(promises)
    .then(() => {
      return boardList;
    });
  }

  const handleStop = useCallback(() => {
    setPlaying(false);
  }, []);
  const handleStart = () => {
    setPlaying(true);
  }
  
  const handlePeriodChange = useCallback(async (item) => {
    if (!item) return;

    setPeriod(item.period);
    const boards = await getLeaderboards(item.period);
    item.attempts.forEach((attempt) => {
      const board = boards[attempt.location];
      if (!board && !Array.isArray(board.results)) return null;

      const userResult = board.results.find((result) => {
        return result.uid === attempt.uid;
      });
      attempt = Object.assign(attempt, {weeklyAmount: userResult.weeklyAmount, monthlyAmount: userResult.monthlyAmount, rank: userResult.rank})
    })
    item.attempts.sort((a, b) => {
      if (a.location > b.location) {
        return 1;
      } 
      if (a.location < b.location) {
        return -1;
      }
      if (a.skillId === b.skillId) {
        return b.reps - a.reps;
      }
      if (a.skillId < b.skillId) {
        return 1;
      }
      if (a.skillId > b.skillId) {
        return -1;
      }
    })
    setAttempts(item.attempts);
    setFilteredAttempts(item.attempts);
    setFiltered(false);
    if (Array.isArray(item.attempts) && item.attempts.length) {
      setCurrentAttempt(item.attempts[0]);
    }
  }, [])
  const handleVideoChange = (attempt) => {
    setCurrentAttempt(attempt);
  }

  const handleFilterChange = () => {
    const filter = !filtered;
    let attemptList = attempts;
    if (filter) {
      attemptList = attemptList.filter((item) => {
        const amount = period.period === 'Month' || period.period === 'Current' ?
          item.monthlyAmount : item.weeklyAmount;

        return amount > 0;
      })
    }
    setFiltered(filter);
    setFilteredAttempts(attemptList);
    if (Array.isArray(attemptList) && attemptList.length) {
      setCurrentAttempt(attemptList[0]);
    }
  }

  const finalizeLeaderboard = async () => {
    const finalize = functions.httpsCallable('finalizeLeaderboard')
    
    trackPromise(
      finalize({seasonId: period.seasonId, period: period.period})
      .catch((err) => {
        // Getting the Error details.
        var message = err.message;
        var details = err.details;
        logError({error: message, info: details, page_location: 'Video Review'});
      })
    );
  }

  const updateLeaderboard = async () => {
    const update = functions.httpsCallable('updateLeaderboards')
    
    trackPromise(
      update({seasonId: period.seasonId, period: period.period})
      .catch((err) => {
        // Getting the Error details.
        var message = err.message;
        var details = err.details;
        logError({error: message, info: details, page_location: 'Video Review'});
      })
    );
  }

  return (
    <>
      <h2 className="mb-1">The Video Review</h2>
      
      <Row xs="1" md="3">
        <Col className='p-1'>
          <AttemptVideoDropdown preferOriginal={true} includeCurrent={true} onChange={handlePeriodChange} period={period} />
          Video Count: {filteredAttempts && filteredAttempts.length}
          <br/>
          <br/>
          <FormCheck>
            <FormCheck.Input checked={filtered} onChange={handleFilterChange} />
            <FormCheck.Label>Filter Videos</FormCheck.Label>
          </FormCheck>
          <Button variant="info" disabled={currentAttempt.final} onClick={updateLeaderboard}>Update Leaderboard</Button>
          <br/>
          <Button variant="primary" disabled={currentAttempt.final} onClick={finalizeLeaderboard}>Finalize Leaderboard</Button>
        </Col>
        <Col className='p-1 bg-dark text-white'>
          <Timer duration={60} onStart={handleStart} onEnd={handleStop} speed={speed} />
          <VideoPlayer showStats={false} attempts={filteredAttempts} play={playing} onPause={handleStop} onSpeedChange={setSpeed} onVideoChange={handleVideoChange}/>
        </Col>
        <Col className='p-1 text-left'>
          <div className="row">
            <label htmlFor="Ninja" className="col-sm-3 text-right">
              Ninja:
            </label>
            <div className="col-sm-9">
              {currentAttempt.displayName}
            </div>     
            <label htmlFor="Ninja" className="col-sm-3 text-right">
              Skill: 
            </label>
            <div className="col-sm-9">
              {currentAttempt.skillName}
            </div>     
            <label htmlFor="Ninja" className="col-sm-3 text-right">
              Reps: 
            </label>
            <div className="col-sm-9">
              {currentAttempt.reps}
            </div>     
            <label htmlFor="Ninja" className="col-sm-3 text-right">
              Location: 
            </label>
            <div className="col-sm-9">
              {currentAttempt.location}
            </div>     
            <label htmlFor="Ninja" className="col-sm-3 text-right">
              Amount: 
            </label>
            <div className="col-sm-9">
              {period === 'Month' ? currentAttempt.monthlyAmount : currentAttempt.weeklyAmount}
            </div>     
            <label htmlFor="Ninja" className="col-sm-3 text-right">
              Reviewed: 
            </label>
            <div className="col-sm-9">
              {currentAttempt.reviewed ? 'Yes' : 'No'}
            </div>
          </div>
          <VideoReviewForm item={currentAttempt} />
        </Col> 
      </Row>
      
    </>
  )
}

export default VideoReview;