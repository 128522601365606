import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import AttemptVideo from './AttemptVideo';

function InfiniteVideoPlayer ({attempts}) {
  const [videos, setVideos] = useState([]);
  const [lastIndex, setLastIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const loadCount = 5;

  const getVideos = async () => {
    for (var i = lastIndex; i < lastIndex + loadCount; i++) {
      if (i === attempts.length) {
        setHasMore(false);
        break;
      }
      const attempt = attempts[i];
      const videoDoc = await attempt.videoRef.get().catch(err => {throw new Error(err)});
      
      if (videoDoc.exists) {
        const data = videoDoc.data();
        attempt.url = data.convertedURL? data.convertedURL : data.url;
      }
      videos.push(attempt);
      
    }
    setVideos(videos);
    setLastIndex(i);
  }

  useEffect(() => {
    setVideos([]);
    setLastIndex(0);
    setHasMore(true);
    getVideos();
  }, [attempts])
  

  if (!(Array.isArray(videos) && videos.length)) return "Loading...";

  return (
    <InfiniteScroll
      dataLength={videos.length}
      next={getVideos}
      hasMore={hasMore}
      loader={<h4>Loading ...</h4>}
    >
      {
        videos.map((attempt, i) => <AttemptVideo attempt={attempt} key={i} />)
      }
    </InfiniteScroll>
  )
}

export default InfiniteVideoPlayer;