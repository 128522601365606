import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useFirebase } from 'services/Firebase';

function Logout() {
  const history = useHistory();
  const auth = useFirebase().auth;
    
  useEffect(() => {
    //Logout user
    try {
      auth().signOut();
    } catch (err) {
      console.error(err);
    }
    history.push('/');
  }, [history, auth]);
  return <></>
}

export default Logout;