import React, {useEffect, useState, useMemo, useCallback} from 'react';
import { useUser } from 'services/User';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import SortableTable from 'components/SortableTable';
import { useFirebase } from 'services/Firebase';
import { FaUserNinja, FaEdit } from 'react-icons/fa';
import { trackPromise } from 'react-promise-tracker';
import BillingInfo from './BillingInfo';


function AthletePicker() {
  const {user, dispatch} = useUser();
  const {db, app} = useFirebase();
  const [athletes, setAthletes] = useState();
  const history = useHistory()
 
  const getData = useCallback(() => {
    const promises = user.athletes.map(athlete => {
      return db.collection('users').doc(user.authId).collection('athletes').doc(athlete.uid).get()
      .then(doc => {
        return doc.data();
      })
    })
    return Promise.all(promises)
    .then(setAthletes)
  }, [user])

  useEffect(() => {
    if (user) {
      trackPromise(getData());
    }
  }, [user, getData])

  const addAthlete = useCallback(() => {
    if(window.confirm('Are you sure you would like to add an athlete to your account?')) {
      const newId = `${user.authId}_${user.athletes.length + 1}`;
      //Add athlete to db, then update user object
      trackPromise(
        db.collection('users').doc(user.authId)
        .collection('athletes').doc(newId)
        .set({createDate: app.firestore.Timestamp.now(), uid: newId, authId: user.authId})
        .then(() => {
          return dispatch({type: 'addAthlete', payload: {user: user, uid: newId}});
        })
        .then(getData)
      )
    }

    return;
  }, [user])

  const editProfile = useCallback(async (uid) => {
    await switchAthletes(uid);
    history.push('/User/Edit')
    return;
  }, []);

  const switchAthletes = useCallback(async (uid) => {
    await dispatch({type: 'switchAthletes', payload: {user: user, uid: uid}});
    history.push('/User')
  }, [user])

  const data = useMemo(() => {
    return athletes || [];
  }, [athletes]);
  const columns = useMemo(() => {
    return [
      {
        Header: 
          <>
            <h4>Athletes</h4>
            <span className="float-right">
              <Button variant="link" className="text-light small" onClick={addAthlete}><FaUserNinja /> add</Button>
            </span>
          </>,
        id: 'Athletes',
        columns: [
          {
            Header: "Name",
            accessor: 'displayName',
            Cell: ({row}) => {
              if (!(row && row.original)) return null;
              
              return (
                <div className="text-left">
                  <Button variant="link" onClick={() => editProfile(row.original.uid)}><FaEdit /></Button> {row.original.displayName}
                </div>
              )
            }
          },
          {
            Header: "Birthday",
            accessor: 'birthday'
          },
          {
            Header: "Member",
            accessor: 'subscribed',
            Cell: ({row}) => {
              if (!(row && row.original)) return null;

              return <BillingInfo athlete={row.original} />
            }
          },
          {
            Header: 'Athlete',
            accessor: 'uid',
            Cell: ({value}) => {
              return (
                <>
                  {value !== user.uid ?
                    <Button variant="info" onClick={() => switchAthletes(value)}>Choose</Button>
                    :
                    <span>Active</span>
                  }
                  
                  
                </>
              )
            }
          }
        ]
      }
    ]
  }, [user, switchAthletes, editProfile])

  if (!(user && Array.isArray(user.athletes) && user.athletes.length)) return null;

  return (
    <>
      <SortableTable columns={columns} data={data} />
    </>
  )
}

export default AthletePicker;