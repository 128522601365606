import React from 'react';
import ReadOnlyProfile from 'components/users/ReadOnlyProfile';
import BillingInfo from 'components/users/BillingInfo';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'react-bootstrap';
import WinningsTable from 'components/users/WinningsTables';
import MessageTable from 'components/notification/MessageTable';
import AthletePicker from './AthletePicker';

const ViewProfile = () => {
  return (
    <>
      <Row>
        <Col><AthletePicker/></Col>
      </Row>
      <br />
      <Row>
        <Col>
          <WinningsTable />
        </Col>
      </Row>
      <br />
      <MessageTable/>
    </>
  );
};

export default ViewProfile;