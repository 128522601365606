import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa';


function CustomTooltip({children}) {

  return (
    <OverlayTrigger
        placement="right"
        trigger={['hover', 'click']}
        overlay={
          <Tooltip>
            {children}
          </Tooltip>
        }
      >
      <FaInfoCircle />
    </OverlayTrigger>
  )
}

export default CustomTooltip;