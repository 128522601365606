import React, {useState, useEffect, useMemo, useCallback} from 'react';
import SortableTable from 'components/SortableTable';
import { trackPromise } from 'react-promise-tracker';
import { useFirebase } from 'services/Firebase';
import PeriodDropdown from 'components/PeriodDropdown';
import { Button, ButtonGroup, Modal, Row, Col, Table } from 'react-bootstrap';



function ViewAttempts () {
  const {db} = useFirebase();
  const [period, setPeriod] = useState();
  const [skills, setSkills] = useState([]);
  const [modalContent, setModalContent] = useState();

  const getData = async (period) => {
    if (!period) return null;

    const skills = [];
    const snapshot = await db.collectionGroup('attempts').where('seasonId', '==', period.seasonId).get().catch(console.error)
    snapshot.forEach(doc => {
      const data = doc.data();
      let skill = skills.find(skill => skill.skillId === data.skillId && skill.uid === data.uid);
      const attempt = {
        period: data.period,
        reps: data.reps,
        reviewed: data.reviewed,
        feedback: data.feedback,
        videoRef: data.videoRef,
        modifiedDate: data.modifiedDate.toMillis()
      }
      if (!skill) {
        skill = {
          skillId: data.skillId,
          skillName: data.skillName,
          uid: data.uid,
          displayName: data.displayName,
          otherAttempts: []
        }
        skills.push(skill);
      } 
      if (period.period === data.period) {
        skill = Object.assign(skill, attempt)
      } 
        
      //Check if attempt exists
      const exists = skill.otherAttempts.some(attempt => {
        return attempt.modifiedDate === data.modifiedDate.toMillis()});
      if (!exists) {
        skill.otherAttempts.push(attempt);
      }
    })
    setSkills(skills);
  }

  const data = React.useMemo(() => {
    return skills.sort((a, b) => {
      if (a.skillName > b.skillName) {
        return -1;
      }
      if (a.skillName < b.skillName) {
        return 1
      } else {
        return b.reps - a.reps
      }
    });
  }, [skills]);
  const columns = React.useMemo(() => [
    {
      Header: 'Skill Name',
      accessor: 'skillName'
    },
    {
      Header: 'Name',
      accessor: 'displayName'
    },
    {
      Header: 'Reps',
      accessor: 'reps'
    },
    {
      Header: 'Reviewed',
      accessor: 'reviewed',
      Cell: ({value}) => {
        return value ? 'Yes' : ''
      }
    },
    {
      Header: 'Feedback',
      accessor: 'feedback'
    },
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({row}) => {
        return (
          <>
            <ButtonGroup>
              <Button variant="secondary" onClick={() => showOtherAttempts(row.original.otherAttempts)} >
                All Attempts
              </Button>
              <Button onClick={() => trackPromise(downloadFile(row.original.videoRef))}>
                Download
              </Button>
            </ButtonGroup>
          </>
        )
      }
    }
  ], [])

  const showOtherAttempts = (otherAttempts) => {
    if (!Array.isArray(otherAttempts)) return null;

    otherAttempts.sort((a, b) => b.modifiedDate - a.modifiedDate);
    
    const Content = (
      <Table striped bordered hover>
        <tbody>
        {
          otherAttempts.map(attempt => {
            return (
              <tr>
                <td>{attempt.period}</td>
                <td>{attempt.reps}</td>
                <td>{attempt.reviewed ? 'Yes' : ''}</td>
                <td>{attempt.feedback}</td>
              </tr>
            )
          })
        }
        </tbody>
      </Table>
    )
    setModalContent(Content);
  }
  const downloadFile = async (videoRef) => {
    if (!videoRef) return null;

    return videoRef.get()
    .then(doc => {
      if (!doc.exists) return null;

      const data = doc.data();
      const url = data.url;
      if (!url) return null;

      try {
        let link = document.createElement('a');
        link.href = url;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (err) {
        console.error(err)
      }
    })
  }
  const handleChange = useCallback(async (period) => {
    trackPromise(getData(period));
    setPeriod(period)
  }, []);

  return (
    <>
      <PeriodDropdown includeCurrent={true} onlySeasons={true} period={period} onPeriodChange={handleChange}/>
      <SortableTable columns={columns} data={data} />
      <Modal show={modalContent} onHide={() => setModalContent('')}>
        <Modal.Header closeButton>All Attempts</Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    </>
  )
}

export default ViewAttempts;