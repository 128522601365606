import React, { useState, useEffect, useRef, useMemo } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import DynamicForm from 'components/forms/DynamicForm';
import { useUser } from 'services/User';
import { useFirebase } from 'services/Firebase';
import Loader from 'components/Loader';
import { trackPromise } from 'react-promise-tracker';

const EditableProfile = () => {
  let { user, dispatch } = useUser();
  const loading = useRef(false);
  const history = useHistory();
  const db = useFirebase().db;

  const [publicProfile, setProfile] = useState({});

  useEffect(() => {
    const getData = async () => {
      const profileDoc = await db.collection('users').doc(user.authId).collection('athletes').doc(user.uid).get();
      if (profileDoc.exists) {
        setProfile(profileDoc.data())
      }
    }

    trackPromise(getData());
  }, [db, user])
  
  const fields = useMemo(() => [
    {
      name: 'displayName',
      label: 'Display Name',
      tooltip: 'The name displayed to everyone on the site.  No profanity!',
      value: publicProfile.displayName,
      as: 'input',
      type: 'text',
      validation: Yup.string()
        .required('*Required')
    },
    {
      name: 'birthday',
      label: 'Birthday',
      tooltip: 'Your birthday will determine your age group.',
      value: publicProfile.birthday,
      as: 'input',
      type: 'date',
      validation: Yup.date()
        .max(new Date(), 'Must be older than a day')
        .required('*Required')
    },
    {
      name: 'gender',
      label: 'Gender',
      tooltip: 'Your gender is needed for monthly medals.',
      value: publicProfile.gender,
      as: 'select',
      options: ['', 'Female', 'Male'],
      validation: Yup.string()
        .required('*Required')
    },
    {
      name: 'passportName',
      label: <span><a href="https://theninjapassport.com/?ref=NinjaSkillsLeague" target="_blank" rel="noopener noreferrer">Ninja Passport</a> User Name</span>,
      tooltip: 'Your Skills League points will be shared with the Ninja Passport Leaderboard.',
      value: publicProfile.passportName,
      as: 'input',
      type: 'text'
    },
    {
      name: 'photo',
      label: 'Photo',
      tooltip: 'Your profile pic, so keep it clean.',
      value: publicProfile.photoURL,
      as: 'input',
      type: 'file',
      fileType: 'image',
      storageRef: `users/${user.uid}/profilePic`
    }
  ], [user, publicProfile])
    
  const handleSubmit = (formValues) => {
    let values = {...formValues};
    
    const updateData = async () => {
      //Remove photo field, we still have URL
      delete values['photo'];
      delete values['photoSize'];
      
      //Copy over the non-updateable fields
      values = Object.assign(publicProfile, {updated: true}, values);
      //Update User
      await db.collection('users').doc(user.authId).collection('athletes').doc(publicProfile.uid).set(values, {merge: true}).catch(console.error);
      await dispatch({type: 'refresh', payload: {activeUid: user.uid}});
      loading.current = false;
      history.push('/Home');
    };
    trackPromise(updateData());
  };

  const handleCancel = () => {
    history.push('/User');
  }
  
  return (
    <>
    <Loader show={!!loading.current} message={loading.current} fullScreen="true"/>
    <DynamicForm fields={fields} onSubmit={handleSubmit} onCancel={handleCancel} />
    </>
  );
};

export default EditableProfile;