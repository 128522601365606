import React from 'react';
import { Badge } from 'react-bootstrap';

function GenderBadge ({gender}) {
  let g = '';
  if (gender) {
    g = gender.substring(0, 1);
  }

  if (!g) return null;
  return (
    <Badge variant="info">{g}</Badge>
  )
}

export default GenderBadge;