import React from 'react';
import { Field, ErrorMessage } from 'formik';
import Tooltip from 'components/Tooltip';


function CheckboxGroupField(props) {
  return (
    <>
      <label htmlFor={props.name} className="col-sm-3 col-form-label text-left text-sm-right">
        {props.label}: {props.tooltip&&<Tooltip>{props.tooltip}</Tooltip>}
      </label>
      <div className="col-sm-9 text-left">
        {
          props.options.map((option) => {
            return (
              <div key={option} className="form-check">
                <Field {...props} value={option} className="form-check-input" key={option} />
                <label className="form-check-label" htmlFor={option}>   
                  {option}
                </label>
              </div>
            )
          })
        }
      </div>
      <div className="error_message">
        <ErrorMessage name={props.name}  />
      </div>
    </>
  )
}

export default CheckboxGroupField;