import React, {useEffect, useState} from 'react';
import { Jumbotron, Image, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Notifications from 'components/notification/Notification';
import { getSeasonId } from 'services/Utilities';
import { useFirebase } from 'services/Firebase';
import InstagramFeed from 'components/InstagramFeed';

function Welcome() {
  const [prizePool, setPrizePool] = useState();
  const db = useFirebase().db;

  useEffect(() => {
    const getData = async () => {
      const seasonId = getSeasonId();
      const doc = await db.collection('seasons').doc(seasonId).get();
      if (doc.exists) {
        const data = doc.data();
        setPrizePool(data.prizePool);
      }
    }
    getData();
  }, [])
  return (
    <>
      <Notifications types={['public']} />
      <Jumbotron>
        
        <Image className="w-25" src="/logo.png"></Image>
        <br/>
        <br/>
        {prizePool && 
          <h3><Badge variant="info">Current Prize Pool: ${prizePool}</Badge></h3>
        }
        <h1>Welcome to the Ninja Skills League!</h1>
        <h3>Complete skills, Top the leaderboard, Win prizes</h3>
        <p>Compete against other ninjas in this virtual league.</p>
        <Button as={Link} variant="primary" to="/HowItWorks">How It Works</Button>
        <Button as={Link} variant="secondary" to="/GettingStarted">Getting Started</Button>
      </Jumbotron>
      <InstagramFeed/>
    </>
  )
}

export default Welcome;