import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import Routes from 'components/Routes';
import { useUser, USER_STATUS } from 'services/User';
import { trackPromise } from 'react-promise-tracker';
import { DateTime } from "luxon";
import './App.css';
import { useHistory, Link } from 'react-router-dom';
import { Container, Alert } from 'react-bootstrap';
import { useFirebase } from 'services/Firebase';
import Error from 'components/ErrorBoundary';
import Loader from 'components/Loader';
import Maintenance from './Maintenance';
import Footer from 'components/Footer';

function App() {
  const firebase = useFirebase();
  const db = useFirebase().db;
  const history = useHistory();
  const {user, dispatch} = useUser();
  const [maintenance, setMaintenance] = useState(false);
  
  //Handle Auth Changes
  useEffect(() => {
    let unsubscribe = null;
    const init = async () => {
      let inMaintenance = false;
      const staticDoc = await db.collection('static')
        .doc('website_status')
        .get()
        .catch((err) => {
          console.error(err);
          firebase.logError({error: err, info: 'Checking Website Status', page_location: 'App'});
        });
      if (staticDoc.exists) {
        const data = staticDoc.data();
        if (data.redirect) {
          window.location.replace(data.redirect);
        }
        inMaintenance = data.maintenance;
        setMaintenance(inMaintenance);
      }
      if (!inMaintenance) {
        unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
          if (authUser) {
            let user = localStorage.getItem('nsl.user');
            user = user && JSON.parse(user);
            const newUser = !(user && user.uid);
            const lastSync = !newUser && user.lastSync && DateTime.fromMillis(Date.parse(user.lastSync));
            let forceUpdate = !(lastSync && lastSync.plus({days: 1}) > DateTime.local());
            forceUpdate = forceUpdate || (DateTime.local() < DateTime.fromISO("2020-08-15"));
            const handleNewUser = async () => {
              user = await trackPromise(dispatch({type: 'stateChange', payload: authUser}));        
            }
            if (newUser || forceUpdate) {
              handleNewUser();
            } else {
              dispatch({type: 'update', payload: user});
            }
          } else {
            localStorage.removeItem('nsl.user');
            dispatch({type: 'reset'});
          }
        });  
      }    
    }
    trackPromise(init());

    return () => {
      if (unsubscribe) {unsubscribe()};
    }
  }, [dispatch, firebase, history]);

  if (maintenance) return <Container><Maintenance/></Container>
  return (
    <div className="flex-wrapper">
      <div className="App">
        <Header />
        <Loader message="Loading"/>
        <Container fluid>
            {
              user.status === USER_STATUS.SIGNED_OUT && history.location.pathname !== '/Login' &&
              <Alert variant="danger">You are not a signed in. <Alert.Link as={Link} to="/Login">Click here to login!</Alert.Link></Alert>
            }
            {
              user.status === USER_STATUS.SIGNED_IN && history.location.pathname !== '/User/Edit' &&
              <Alert variant="danger">You do not have a user profile. <Alert.Link as={Link} to="/User/Edit">Click here to create one!</Alert.Link></Alert>
            }
            {
              user.status === USER_STATUS.REGISTERED && history.location.pathname !== '/Join' &&
              <Alert variant="danger">You are not a member. <Alert.Link as={Link} to="/Join">Click here to join!</Alert.Link></Alert>
            }
            {
              user.status === USER_STATUS.SUBSCRIBED && history.location.pathname !== '/User/Edit' &&
              <Alert variant="warning">Congrats, you are now a member! <Alert.Link as={Link} to="/User/Edit">Click here to create your profile.</Alert.Link></Alert>
            }
          <Error>
            <Routes />
          </Error>
        </Container>
        <br/>
        <br/>
      </div>
      <Footer />
    </div>
  );
}

export default App;