import React from 'react';
import { Button } from 'react-bootstrap';
import { useUser, USER_STATUS } from 'services/User';
import { useFirebase } from 'services/Firebase';
import { trackPromise } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';


const BillingInfo = ({athlete}) => {
  const { user, dispatch } = useUser();
  const {functions, logError, logEvent} = useFirebase();
  
  const history = useHistory();
  
  const cancelSubscription = async () => {
    const cancel = async () => {
      const cancelFunction = functions.httpsCallable('cancelSubscription');
      const result = await cancelFunction({uid: athlete.uid})
      .catch((err) => {
        // Getting the Error details.
        var message = err.message;
        var details = err.details;
        logError({error: message, info: details, page_location: 'Cancel Subscription'});
      });
      
      if (result && result.data && result.data.status === true) {
        try {
          logEvent({type: 'cancel_membership', user: athlete.uid});
        } catch (err) {
          console.error(err);
        }
        await dispatch({type: 'refresh', payload: {activeUid: athlete.uid}});
        history.push('/Welcome');
      } else {
        alert('Error canceling membership, please contact support: support@skillsleague.ninja');
        logError({error: JSON.stringify(result), page_location: 'Cancel Subscription'});
      }
    }

    if(window.confirm('Are you sure?')) {
      trackPromise(cancel());      
    }
  };

  const startMembership = async () => {
    await dispatch({type: 'switchAthletes', payload: {user: user, uid: athlete.uid}})
    history.push("/Join");
  }
  
  if (!athlete) return null;

  return (
    <div>
      { athlete.subscribed ?
        <Button onClick={cancelSubscription} variant="danger">
          Cancel Membership
        </Button>
        :
        <Button onClick={startMembership} variant="primary" >
          Start Membership
        </Button>
      }
    </div>
  );
};

export default BillingInfo;