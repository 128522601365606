import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useUser } from 'services/User';
import { useFirebase } from 'services/Firebase';
import { getSeasonId, getParentAccountId } from 'services/Utilities';
import { Row, Col, Container } from 'react-bootstrap';
import { trackPromise } from 'react-promise-tracker';
import UserInfo from 'components/users/UserInfo';
import AttemptVideoDropdown from 'components/video/AttemptVideoDropdown';
import Notifications from 'components/notification/Notification';
import LocationStats from 'components/users/LocationStats';
import AttemptVideo from 'components/video/AttemptVideo';

function Home() {
  let {uid} = useParams();
  let location = useLocation();
  const currentPeriod = location.state && location.state.period;
  const {user} = useUser();
  const db = useFirebase().db;
  const currentSeasonId = getSeasonId();
  
  const [attempts, setAttempts] = useState([]);
  const [period, setPeriod] = useState(currentPeriod);
  const [seasons, setSeasons] = useState();
  
  const [viewedUser, setViewedUser] = useState();
  
  //Should not be here if not logged in
  if (!user) {
    throw new Error('Restricted')
  }
  uid = uid || user.uid;
  
  useEffect(() => {
    let seasonsData = [];
      
    const getInfo = async () => {
      //Get Profile
      try {
        const authId = getParentAccountId(uid);
        const profileDoc = await db.collection('users').doc(authId).collection('athletes').doc(uid)
        .get()
        .catch(err => {throw new Error('Error retrieving profile: ' + err.message)});
        
        if (profileDoc.exists) {
          const data = profileDoc.data();
          const loadData = ({seasons, ...profile}) => {
            if (seasons) {
              seasonsData = seasons;
            } 
            if (seasonsData.indexOf(currentSeasonId) === -1) {
              seasonsData.push(currentSeasonId);
            }
            
            seasonsData.sort((a, b) => b - a);
            setViewedUser(profile);

            setSeasons(seasonsData);
          }
          loadData(data);      
        }
      } catch (err) {
        console.error(err)
      }
    };
    trackPromise(getInfo());
  }, [uid, user, currentSeasonId])

  const handlePeriodChange = useCallback(async (item) => {
    setPeriod(item.period);

    //Hide attempts for current period
    if (user.uid === uid || item.period.period !== 'Current') {
      setAttempts(item.attempts);
    } else {
      setAttempts([])
    }   
  }, [uid]);

  if (!(viewedUser && seasons)) return null;

  return (
    <>
      {user.uid === uid && <Notifications types={['all']} />}
      <br/>
      <Container>
        <Row className="align-items-end mb-1">
          <Col xs="8" className="text-center">
            <UserInfo profile={viewedUser} />
          </Col>
          <Col xs className="text-right">
            <AttemptVideoDropdown includeCurrent={true} uid={uid} period={period} onChange={handlePeriodChange} onlySeasons={seasons} />
          </Col>
        </Row>
        <LocationStats period={period} uid={uid} />
        <br/>
        {
          attempts.map((attempt, i) => <AttemptVideo key={i} attempt={attempt} />)
        }
      </Container>
    </>
  );
}

export default Home;