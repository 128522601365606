import React from 'react'
import { Container } from 'react-bootstrap';
import Subscribe from 'components/users/Subscribe';


function Join() {
  return (
    <Container>
      <h2>Purchase Membership</h2>
      <Subscribe />
    </Container>
  );
}

export default Join;