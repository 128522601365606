import React from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import LocationIcon from 'components/LocationIcon';
import { FaVideo, FaVideoSlash } from 'react-icons/fa';
import Video from 'components/video/Video';
import Avatar from 'components/users/Avatar';
import FormattedDate from 'components/FormattedDate';



function AttemptVideo({attempt}) {
  if (!attempt) return null;
  return (
    <Card className="mb-2">
      <Card.Header className="pt-0">
        <div className="text-right text-muted small"><FormattedDate date={attempt.modifiedDate} format="ccc LLL d, yy tt" /></div>
        <div className="text-left d-flex">
          <Link to={`/Home/${attempt.uid}`} className="flex-grow-1 align-items-center d-flex" >
            <Avatar src={attempt.photoURL}></Avatar> 
            <strong className="pl-2 align-self-center">{attempt.displayName}</strong>
          </Link>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <Video src={attempt.url} showSpeed={false} />
      </Card.Body>
      <Card.Footer>
        <Row xs={2} className="align-items-center">
          <Col xs={3}><LocationIcon location={attempt.location}/></Col>
          <Col xs={9}>
            {attempt.reviewed?
              <div className="small text-info"><FaVideo/> Video Reviewed</div>
            :
              <div className="small text-warning"><FaVideoSlash/> Not Reviewed</div>
            }
            <h4>{attempt.skillName}</h4>
            <h2 className={attempt.feedback?"text-warning":"text-primary"}>{attempt.reps} <span className="small text-muted">rep{attempt.reps !== 1 && 's'}{attempt.feedback && '*'}</span></h2> 
            {attempt.feedback && <p className="text-italic text-warning">* {attempt.feedback}</p>}
          </Col>
        </Row>
      </Card.Footer>              
    </Card>
  )
}

export default AttemptVideo;