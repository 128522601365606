import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';


function SkillSort({onSort, status}) {
  
  const SortIcon = (props) => {
    if (props.item === status.currentSort) {
      return status.sortDirection === 1 ? 
        <FaArrowCircleUp/> : <FaArrowCircleDown/>
    } else {
      return <span className="mr-2">&nbsp;</span>;
    }
  };
  return (
    <Dropdown onSelect={onSort}>
      <Dropdown.Toggle variant="link">Sort by:</Dropdown.Toggle>
      <Dropdown.Menu>               
        {Object.keys(status.sortKeys).map(key =>
          <Dropdown.Item className="pl-1" eventKey={key} key={key}><SortIcon item={key}/> {key}</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SkillSort;